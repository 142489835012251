import React from 'react';
import { Avatar, Result } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { StageProgress } from 'components/ui/StageProgress';
import { ReactComponent as LaptopSvg } from 'assets/images/svg/laptop-01.svg';

import { formatDateDDMMYYYY } from 'utils/dateUtils';
import { EStage, EStatus } from 'constants/stageProgress';
import { TCandidatesListTableRecord } from './types';
import { wordScore } from 'utils/wordScore';
import { chooseCandidateAvatarByID } from 'utils/avatar';

import cx from './index.module.scss';

export const renderNameCell = (_, record: TCandidatesListTableRecord) => {
  const fullName = [record.lastName, record.firstName, record.middleName]
    .filter((val) => val)
    .join(' ');

  const directionWithVacancy = [record.direction?.title, record.vacancy?.name]
    .filter((val) => val)
    .join(', ');

  const avatarIcon = chooseCandidateAvatarByID(record.id);
  return (
    <div className={cx.candidate_info}>
      <Avatar className={cx.avatar} src={avatarIcon} icon={<UserOutlined />} size={44} />
      <div className={cx.column}>
        <div className={cx.name}>{fullName}</div>
        <div className={cx.vacancy}>{directionWithVacancy}</div>
      </div>
    </div>
  );
};

export const renderProgressCell = (_, record: TCandidatesListTableRecord) => (
  <StageProgress currentStage={record.stage as EStage} status={record.status as EStatus} />
);

export const renderTechTaskScoreCell = (_, record) => (
  <span className={cx.score}>{wordScore(record.techTaskScore)}</span>
);

export const renderInterviewScoreCell = (_, record) => (
  <span className={cx.score}>{wordScore(record.interviewScore)}</span>
);

export const renderDateCell = (_, record: TCandidatesListTableRecord) => {
  const date = formatDateDDMMYYYY(record.createdAt);

  return <span className={cx.date}>{date}</span>;
};

export const emptyTable = () => (
  <Result className={cx.empty_result} subTitle="Кандидаты не найдены..." icon={<LaptopSvg />} />
);
