import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import cn from 'classnames';

import { useAppDispatch } from 'store/hooks';
import { setQuestionScore } from 'store/interview/slice';
import { SCORE_OPTIONS } from '..';

import cx from '../index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

type TProps = {
  blockId: string;
  serialNumber: number;
  technical: boolean;
};

export const QuestionEdit = ({ blockId, serialNumber, technical }: TProps) => {
  const form = Form.useFormInstance();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (technical) {
      dispatch(
        setQuestionScore({
          name: form.getFieldValue([blockId, serialNumber]).id,
          value: form.getFieldValue([blockId, serialNumber]).score,
        }),
      );
    }
  }, []);

  const handleSelectChange = (e) => {
    dispatch(setQuestionScore({ name: form.getFieldValue([blockId, serialNumber]).id, value: e }));
  };
  return (
    <div className={cx.root}>
      <div className={cx.question_and_score}>
        <Form.Item
          label={`Вопрос ${serialNumber + 1}`}
          className={cn(cx.question, !technical && cx.non_tech)}
          name={[serialNumber, 'question']}
        >
          <TextArea
            maxLength={1000}
            size="large"
            disabled
            autoSize={{ maxRows: 4, minRows: 1 }}
            className={cx.text_area}
          />
        </Form.Item>
        {technical && (
          <Form.Item label="Балл за ответ" className={cx.score} name={[serialNumber, 'score']}>
            <Select size="large" placeholder="Укажите балл" onChange={handleSelectChange}>
              {SCORE_OPTIONS.map((item) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>
      <Form.Item
        label="Комментарий"
        className={cx.comments}
        name={[serialNumber, 'comment']}
        rules={[{ required: !technical, message: '' }]}
      >
        <TextArea placeholder="Написать комментарий..." showCount maxLength={1000} rows={4} />
      </Form.Item>
    </div>
  );
};
