import { api } from "network/api/api";
import { apiRoutes } from "network/apiRoutes";
import { TLoginUser, TLoginUserResponse } from "./types";

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<TLoginUserResponse, TLoginUser>({
            query: (body) => ({
                url: `${apiRoutes.LOGIN}`,
                method: 'POST',
                body,
            }),
        })
    }),
})

export const { useLoginMutation } = authApi;
