import { TInterviewFormValues, TCreateCustomQuestionsRequest, TPostInterviewRequest } from "store/interview/types";

export const processResultsData = (results: TInterviewFormValues, uid: string) => {
    const blockIdsArray = Object.keys(results).sort();
    const resultQuestions: TPostInterviewRequest = [];
    const customQuestions: TCreateCustomQuestionsRequest = [];
    const customQuestionsResults: { score?: number, comment: string }[] = [];
    for (let i = 0; i < blockIdsArray.length; i += 1) {
        const questions = results[blockIdsArray[i]]
        const techQuestion = i === 0;
        for (let j = 0; j < questions.length; j += 1) {
            const currentQuestion = questions[j];
            if (currentQuestion.custom && currentQuestion.question) {
                if (techQuestion) {
                    if (currentQuestion.score) {
                        customQuestions.push({ block: Number(blockIdsArray[i]), candidate_id: Number(uid), text: currentQuestion.question });
                        customQuestionsResults.push({ score: currentQuestion.score, comment: currentQuestion.comment });
                    }
                } else if (currentQuestion.comment) {
                    customQuestions.push({ block: Number(blockIdsArray[i]), candidate_id: Number(uid), text: currentQuestion.question });
                    customQuestionsResults.push({ comment: currentQuestion.comment });
                }
            } else if (techQuestion) {
                if (currentQuestion.score && !currentQuestion.custom) {
                    resultQuestions.push({ question: Number(currentQuestion.id), score: currentQuestion.score, comment: currentQuestion.comment })
                }
            } else if (currentQuestion.comment) {
                resultQuestions.push({ question: Number(currentQuestion.id), comment: currentQuestion.comment })
            }

        }
    }
    return { customQuestions, customQuestionsResults, resultQuestions };
}