import React from 'react';
import { ConfigProvider, Table, TablePaginationConfig, TableProps, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SorterResult } from 'antd/es/table/interface';
import { TableRowSelection } from 'antd/lib/table/interface';

import { CandidateListFilters } from './Filters';
import { emptyTable } from './cellRenderers';

import { routes } from 'routing/routes';
import { useGetCandidatesListQuery } from 'store/candidatesList';
import {
  getCandidatesListFilters,
  getCandidatesListPagination,
  getCandidatesListSelectionList,
  getCandidatesListShowOnlySelected,
  getCandidatesListSort,
} from 'store/candidatesList/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CandidatesListActions } from 'store/candidatesList/slice';
import { sortColumnAdapter, sortOrderAdapter } from './helpers';
import { columns } from './columns';
import { TCandidatesListTableRecord } from './types';

import cx from './index.module.scss';

const { Title } = Typography;

// Row key getter
const rowKey = (record) => record.id;

export const CandidatesList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sort = useAppSelector(getCandidatesListSort);
  const filters = useAppSelector(getCandidatesListFilters);
  const pagination = useAppSelector(getCandidatesListPagination);
  const selectedRows = useAppSelector(getCandidatesListSelectionList);
  const showOnlySelected = useAppSelector(getCandidatesListShowOnlySelected);

  const {
    data: candidates,
    isFetching,
  } = useGetCandidatesListQuery({
    ...sort,
    ...filters,
    ...pagination,
  });

  let dataSource = candidates?.data || [];
  let totalCandidates = candidates?.total || 0;

  if (showOnlySelected) {
    dataSource = selectedRows;
    totalCandidates = dataSource.length;
  }

  const tablePagination: TablePaginationConfig = {
    size: 'small',
    total: totalCandidates,
    current: pagination.skip / pagination.take + 1,
    pageSize: pagination.take,
    showSizeChanger: true,
    pageSizeOptions: [6, 10, 25, 50],
    onShowSizeChange: (_, size) => {
      dispatch(CandidatesListActions.setPageSize(size));
    },
    onChange: (page) => {
      dispatch(CandidatesListActions.setPage(page));
    },
  };

  const tableRowSelection: TableRowSelection<TCandidatesListTableRecord> = {
    selectedRowKeys: selectedRows.map(({ id }) => id),
    hideSelectAll: true,
    onSelect: (record, selected) => {
      if (selected) {
        dispatch(CandidatesListActions.setSelectedRows([...selectedRows, record]));
      } else {
        dispatch(CandidatesListActions.setSelectedRows(selectedRows.filter((row) => row.id !== record.id)));
      }
    },
  };

  const onRow: TableProps<TCandidatesListTableRecord>['onRow'] = ({ id }) => ({
    onClick: () => {
      navigate(routes.candidateProfile(`${id}`));
    },
  });

  const onChange = (_pagination, _filters, sorter: SorterResult<TCandidatesListTableRecord>, { action }) => {
    if (action === 'sort') {
      const sortOrder = sortOrderAdapter(sorter.order);
      const columnName = sortColumnAdapter(sorter.columnKey);
      dispatch(CandidatesListActions.setSort(
        {
          order: sortOrder,
          orderColumnName: columnName,
        },
      ));
    }
  };

  return (
    <ConfigProvider renderEmpty={emptyTable}>
      <div className={cx.wrapper}>
        <div className={cx.container}>
          <Title className={cx.title}>
            Список кандидатов
          </Title>
          <CandidateListFilters />
          <Table className={cx.table}
                 rowClassName={cx.table_row}
                 tableLayout='auto'
                 columns={columns}
                 dataSource={dataSource}
                 scroll={{ y: 'auto' }}
                 loading={isFetching}
                 rowKey={rowKey}
                 onRow={onRow}
                 pagination={tablePagination}
                 rowSelection={tableRowSelection}
                 showSorterTooltip={false}
                 onChange={onChange}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};
