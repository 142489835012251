import React from 'react';

import { Tabs } from 'antd';

import { ResultInterview } from './ResultsInterview';
import { ResultTest } from './ResultTest';

import style from './index.module.scss';

type TProps = { uid: string };

export const ResultsBlock = ({ uid }: TProps) => {
  const tabsItems = [
    { label: 'Результат интервью', key: 'item-1', children: <ResultInterview uid={uid} /> }, // remember to pass the key prop
    { label: 'Результат тестового задания', key: 'item-2', children: <ResultTest uid={uid} /> },
  ];

  return (
    <div className={style.container}>
      <Tabs className={style.tabs}>
        {tabsItems.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
