import { EStage, EStatus } from 'constants/stageProgress';
import { ELevel } from 'constants/level';
import { TInterviewQuestion } from 'store/interview/types';
import { TUserInterviewInfo } from 'utils/localStorage/types';

export const isArrayOfStatuses = (value: unknown): value is EStatus[] =>
  Array.isArray(value) && value.every((item) => Object.values(EStatus).includes(item));

export const isArrayOfStages = (value: unknown): value is EStage[] =>
  Array.isArray(value) && value.every((item) => Object.values(EStage).includes(item));

export const isArrayOfLevels = (value: unknown): value is ELevel[] =>
  Array.isArray(value) && value.every((item) => Object.values(ELevel).includes(item));

export const isArrayOfStrings = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every(item => typeof item === "string");

export const isQuestionObject = (technical: boolean) =>
  (value: unknown): value is TInterviewQuestion => {
    const testValue = value as TInterviewQuestion;
    return value instanceof Object && value !== null
      && (technical ? (typeof testValue.score === 'number' || testValue.score === null) : typeof testValue.score === 'undefined')
      && typeof testValue.question === 'string'
      && typeof testValue.comment === 'string'
      && typeof testValue.id === 'string'
      && typeof testValue.custom === 'boolean'
  }


export const isUserInterviewInfoObject = (value: unknown): value is TUserInterviewInfo => {
  const testValue = value as TUserInterviewInfo;
  return value instanceof Object && value !== null
    && isArrayOfStrings(testValue.value)
    && typeof testValue.expiry === "number"
}




