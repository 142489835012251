export enum EStatus {
  NEUTRAL = 'Нейтральный',
  PENDING = 'На рассмотрении',
  HIRED = 'Принят',
  REJECTED = 'Отказ',
}

// export type TStatus = [EStatus.NEUTRAL, EStatus.HIRED, EStatus.PENDING, EStatus.REJECTED];

export enum EStage {
  ADDED = 'Добавлен',
  INTERVIEW = 'Техническое интервью',
  TASK_SENT = 'Выдано задание',
  TASK_COMPLETED = 'Задание выполнено',
  ALL_TASKS_COMPLETED = 'Завершил испытания',
}


export type TStage = [
  EStage.ADDED,
  EStage.INTERVIEW,
  EStage.TASK_SENT,
  EStage.TASK_COMPLETED,
  EStage.ALL_TASKS_COMPLETED,
];

export const STAGES: TStage = [
  EStage.ADDED,
  EStage.INTERVIEW,
  EStage.TASK_SENT,
  EStage.TASK_COMPLETED,
  EStage.ALL_TASKS_COMPLETED,
];
