import { isArrayOfStrings, isUserInterviewInfoObject } from "utils/typeGuards";
import { getFromLocalStorage } from "./getFromLocalStorage";

const clearQuestions = (blockIdArray: string[]) => {
    for (let i = 0; i < blockIdArray.length; i += 1) {
        const questionIdArray = getFromLocalStorage(blockIdArray[i], isArrayOfStrings);
        if (questionIdArray) {
            for (let j = 0; j < questionIdArray.length; j += 1) {
                localStorage.removeItem(questionIdArray[j]);
            }
            localStorage.removeItem(blockIdArray[i]);
        }
    }
}

export const clearExpiredInterviews = () => {
    const interviewArray = getFromLocalStorage("interview", isArrayOfStrings);
    if (interviewArray) {
        const uidsToDelete: string[] = [];
        for (let i = 0; i < interviewArray.length; i += 1) {

            const userInterviewInfo = getFromLocalStorage(interviewArray[i], isUserInterviewInfoObject);
            if (userInterviewInfo) {
                const now = new Date();
                if (userInterviewInfo.expiry < now.getTime()) {
                    clearQuestions(userInterviewInfo.value);
                    localStorage.removeItem(interviewArray[i]);
                    uidsToDelete.push(interviewArray[i]);
                }
            } else {
                uidsToDelete.push(interviewArray[i]);
            }
        }
        if (uidsToDelete.length !== 0) {
            localStorage.setItem("interview", JSON.stringify(interviewArray.filter(item => !uidsToDelete.includes(item))));
        }
    }

}

export const clearInerviewFromLSAfterSending = (uid: string) => {
    const userInterviewInfo = getFromLocalStorage(uid, isUserInterviewInfoObject);
    if (userInterviewInfo) {
        clearQuestions(userInterviewInfo.value);
        localStorage.removeItem(uid);
    }

    const interviewArray = getFromLocalStorage("interview", isArrayOfStrings);
    if (interviewArray) {
        localStorage.setItem("interview", JSON.stringify(interviewArray.filter(item => item !== uid)));
    }
    else {
        localStorage.removeItem("interview");
    }
}


