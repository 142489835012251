import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { apiRoutes } from "network/apiRoutes";
import { logout } from "store/auth/slice";
import { clientCookies } from "utils/cookies";
import { url } from "./url";


const protectedQuery = fetchBaseQuery({
    baseUrl: url(),
    prepareHeaders: (headers) => {
        headers.set("Authorization", `Bearer ${clientCookies.getToken()}`);
        return headers;
    }
});

const publicQuery = fetchBaseQuery({
    baseUrl: url(),
});

export const customQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    if (`/${api.endpoint}` === apiRoutes.LOGIN) {
        const result = await publicQuery(args, api, extraOptions);
        return result;
    }
    const result = await protectedQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        api.dispatch(logout());
    }
    return result;
}
