import React from 'react';
import { Popover } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';

import { PopoverEdit } from './PopoverContent/PopoverEdit';
import { PopoverDelete } from './PopoverContent/PopoverDelete';
import { ReactComponent as IconEdit } from 'assets/images/svg/edit.svg';
import { ReactComponent as IconClose } from 'assets/images/svg/Close.svg';
import { Button } from 'components/ui/Button';

import cx from './index.module.scss';

type TProps = { uid: string };
type TContentItem = { icon: React.FC; content: React.ReactNode };

export const ButtonWithPopover = ({ uid }: TProps) => {
  const contentItems: TContentItem[] = [
    {
      icon: IconEdit,
      content: <PopoverEdit uid={uid} />,
    },
    { icon: IconClose, content: <PopoverDelete uid={uid} /> },
  ];

  const content = (
    <ul className={cx.container}>
      {contentItems.map((item) => (
        <li key={nanoid()}>
          <span>{React.createElement(item.icon)}</span>
          {item.content}
        </li>
      ))}
    </ul>
  );

  return (
    <Popover content={content} placement="bottomRight" trigger="click">
      <div>
        <Button type="text">
          <EllipsisOutlined />
        </Button>
      </div>
    </Popover>
  );
};
