import React, { useMemo } from 'react';
import { Checkbox, CheckboxOptionType, Collapse, Dropdown } from 'antd';

import { useAppDispatch } from 'store/hooks';
import { CandidatesListActions } from 'store/candidatesList/slice';
import { useGetAllDirectionsQuery } from 'store/directions';
import { useGetAllVacanciesQuery } from 'store/vacancy';
import { levelOptions, stageOptions, statusOptions } from './checkboxOptions';
import { isArrayOfLevels, isArrayOfStages, isArrayOfStatuses } from 'utils/typeGuards';
import { useSelector } from 'react-redux';
import {
  getCandidatesListFilterByDirection,
  getCandidatesListFilterByLevel, getCandidatesListFilterByStage, getCandidatesListFilterByStatus,
  getCandidatesListFilterByVacancy,
} from 'store/candidatesList/selectors';

const { Panel } = Collapse;

type TOtherFiltersProps = {
  labelClassName: string;
  overlayClassName: string
}

export const OtherFilters = ({ labelClassName, overlayClassName }: TOtherFiltersProps) => {
  const dispatch = useAppDispatch();
  const vacancyValue = useSelector(getCandidatesListFilterByVacancy);
  const levelValue = useSelector(getCandidatesListFilterByLevel);
  const directionValue = useSelector(getCandidatesListFilterByDirection);
  const stageValue = useSelector(getCandidatesListFilterByStage);
  const statusValue = useSelector(getCandidatesListFilterByStatus);
  const { data: vacancies } = useGetAllVacanciesQuery();
  const { data: directions } = useGetAllDirectionsQuery();

  const vacancyOptions = useMemo(() =>
      vacancies
        ? vacancies.map(({ id, name }): CheckboxOptionType => ({
          label: name,
          value: id,
        }))
        : [],
    [vacancies]);

  const directionOptions = useMemo(() =>
      directions
        ? directions.map(({ id, title }): CheckboxOptionType => ({
          label: title,
          value: id,
        })) : [],
    [directions]);

  const onChangeVacancy = (checkedValues) => {
    dispatch(CandidatesListActions.setFilterByVacancy(checkedValues));
  };

  const onChangeDirection = (checkedValues) => {
    dispatch(CandidatesListActions.setFilterByDirection(checkedValues));
  };

  const onChangeLevel = (checkedValues) => {
    if (isArrayOfLevels(checkedValues)) {
      dispatch(CandidatesListActions.setFilterByLevel(checkedValues));
    }
  };

  const onChangeStage = (checkedValues) => {
    if (isArrayOfStages(checkedValues)) {
      dispatch(CandidatesListActions.setFilterByStage(checkedValues));
    }
  };

  const onChangeStatus = (checkedValues) => {
    if (isArrayOfStatuses(checkedValues))
      dispatch(CandidatesListActions.setFilterByStatus(checkedValues));
  };

  const menu = (
    <Collapse accordion ghost>
      <Panel key='vacancy'
             header='Вакансия'>
        <Checkbox.Group options={vacancyOptions}
                        value={vacancyValue}
                        onChange={onChangeVacancy} />
      </Panel>
      <Panel key='direction'
             header='Направление'>
        <Checkbox.Group options={directionOptions}
                        value={directionValue}
                        onChange={onChangeDirection} />
      </Panel>
      <Panel key='level'
             header='Уровень'>
        <Checkbox.Group options={levelOptions}
                        value={levelValue}
                        onChange={onChangeLevel} />
      </Panel>
      <Panel key='stage'
             header='Этап отбора'>
        <Checkbox.Group options={stageOptions}
                        value={stageValue}
                        onChange={onChangeStage} />
      </Panel>
      <Panel key='status'
             header='Статус кандидата'>
        <Checkbox.Group options={statusOptions}
                        value={statusValue}
                        onChange={onChangeStatus} />
      </Panel>
    </Collapse>
  );

  return (
    <Dropdown overlay={menu}
              overlayClassName={overlayClassName}>
      <span className={labelClassName}>
        Все фильтры
      </span>
    </Dropdown>
  );
};