import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CandidateForm } from 'components/CandidateForm';
import { ModalWarning, TModalWarningProps } from 'components/ui/Modal';

import { useEditCandidateByIdMutation, useGetCandidateByIdQuery } from 'store/candidate';
import { TRouterParams } from 'routing/types';
import { TFormValues } from 'store/candidate/types';
import { routes } from 'routing/routes';

export const EditCandidate: FC = () => {
  const { candidateId: id } = useParams<keyof TRouterParams>() as TRouterParams;
  const [editCandidate, result] = useEditCandidateByIdMutation();
  const { data } = useGetCandidateByIdQuery(id);
  const { isLoading, isError, isSuccess } = result;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess || isError) {
      setIsModalOpen(true);
    }
  }, [isSuccess, isError]);

  const onFinish = (values: TFormValues) => {
    editCandidate({ id, ...values });
  };

  const modalSuccessProps: TModalWarningProps = {
    handleOk() {
      setIsModalOpen(false);
      navigate(-1);
    },
    handleCancel() {
      setIsModalOpen(false);
      navigate(routes.candidatesList);
    },
    contentText: 'Данные кандидата изменены',
    cancelText: 'К списку кандидатов',
    okText: 'В карточку',
    type: 'success',
  };

  const modalErrorProps: TModalWarningProps = {
    handleOk() {
      setIsModalOpen(false);
      navigate(-1);
    },
    handleCancel() {
      setIsModalOpen(false);
    },
    contentText: 'Ошибка при обращении к серверу',
    cancelText: 'Понять',
    okText: 'Простить',
    type: 'error',
  };

  return (
    <>
      <CandidateForm onFinish={onFinish} loading={isLoading} edit initialState={data} />
      <ModalWarning
        visible={isModalOpen && (isSuccess || isError)}
        {...(isSuccess ? modalSuccessProps : modalErrorProps)}
      />
    </>
  );
};
