import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';

import { api } from 'network/api/api';
import authReducer from 'store/auth/slice';
import userReducer from 'store/user/slice';
import candidatesListReducer from 'store/candidatesList/slice';
import interviewReducer from "store/interview/slice"

const reducers = {
  auth: authReducer,
  interview: interviewReducer,
  user: userReducer,
  candidatesList: candidatesListReducer,
  [api.reducerPath]: api.reducer,
};

const rootReducer = combineReducers(reducers);

const resettableRootReducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
