import React from 'react';
import { Avatar, Row, Col, Tag, Space, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { FullName } from './FullName';
import { ReactComponent as IconPhone } from 'assets/images/svg/phone.svg';
import { ReactComponent as IconMail } from 'assets/images/svg/mail.svg';
import { ButtonWithPopover } from 'components/CandidateProfile/PersonalData/PersonalDataView/ButtonWithPopover';
import {
  ContactsList,
  TContactsListItem,
} from 'components/CandidateProfile/PersonalData/PersonalDataView/ContactsList';

import { TCandidate } from 'store/candidate/types';
import { chooseCandidateAvatarByID } from 'utils/avatar';

import cx from './index.module.scss';

export const PersonalDataView = ({ candidate }: { candidate: TCandidate }) => {
  const { lastName, firstName, middleName, phone, email, direction, vacancy, level, id } =
    candidate;

  const avatar = chooseCandidateAvatarByID(id);

  const contactsList: Array<TContactsListItem> = [
    { icon: IconPhone, content: phone, label: 'phone' },
    { icon: IconMail, content: email, label: 'email' },
  ];

  const fullName: Array<string | undefined> = [lastName, firstName, middleName];

  return (
    <div className={cx.wrapper}>
      <Row align="stretch" className={cx.top_half} wrap={false}>
        <Col>
          <Avatar src={avatar} size={64} className={cx.avatar} icon={<UserOutlined />} />
        </Col>
        <Col>
          <Space direction="vertical" size="small">
            <FullName {...{ fullName }} />
            <Row className={cx.job}>{vacancy?.name || 'без вакансии'}</Row>
          </Space>
        </Col>
        <Col className={cx.button_popover_col}>
          <ButtonWithPopover uid={id} />
        </Col>
      </Row>
      <Divider />
      <div className={cx.bottom_half}>
        <Row className={cx.tags}>
          <Tag>{direction.title}</Tag>
          <Tag>{level}</Tag>
        </Row>
        <ContactsList items={contactsList} />
      </div>
    </div>
  );
};
