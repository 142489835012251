import React, { useEffect } from 'react';
import { Form } from 'antd';
import cn from 'classnames';
import { Navigate, useParams } from 'react-router-dom';

import Title from 'antd/lib/typography/Title';
import { Button } from 'components/ui/Button';
import { Loader } from 'components/ui/Loader';
import { Block } from '../Block';

import { hint } from 'components/ui/Hint';
import { TRouterParams } from 'routing/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetInterviewBlocksQuery, useGetInterviewQuestionsQuery } from 'store/interview';
import {
  getInterviewInitialValues,
  getOverallMark,
  getPartialSubmitCancelledStatus,
} from 'store/interview/selectors';
import { clearInterview, prepareQuestionData } from 'store/interview/slice';
import { TInterviewFormValues } from 'store/interview/types';
import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { isArrayOfStrings } from 'utils/typeGuards';
import { setToLSWithOneDayExpiry } from '../../../utils/localStorage/setToLSWithOneDayExpiry';
import { customFormValidation, ECustomValidationStatuses } from './customFormValidation';
import { routes } from 'routing/routes';

import cx from './index.module.scss';

type TProps = {
  onFinish: (values: TInterviewFormValues, status: ECustomValidationStatuses) => void;
  isLoading: boolean;
};

export const InterviewForm = ({ onFinish, isLoading }: TProps) => {
  const [form] = Form.useForm<TInterviewFormValues>();
  const overallMark = useAppSelector(getOverallMark);
  const isPartialSubmitCancelled = useAppSelector(getPartialSubmitCancelledStatus);
  const initialValues = useAppSelector(getInterviewInitialValues);
  const dispatch = useAppDispatch();
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const {
    isSuccess: isGetBlocksSuccess,
    isError: isGetBlocksError,
    data: blocks,
  } = useGetInterviewBlocksQuery();
  const {
    isSuccess: isGetQuestionsSuccess,
    isError: isGetQuestionsError,
    data: questions,
  } = useGetInterviewQuestionsQuery(uid);

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();
    onFinish(formValues, customFormValidation(formValues));
  };

  useEffect(
    () => () => {
      dispatch(clearInterview());
    },
    [],
  );

  useEffect(() => {
    if (isPartialSubmitCancelled) {
      form.validateFields();
    }
  }, [isPartialSubmitCancelled]);

  useEffect(() => {
    if (isGetBlocksSuccess && isGetQuestionsSuccess) {
      dispatch(prepareQuestionData({ blocks, questions, uid }));
    }
  }, [isGetBlocksSuccess, isGetQuestionsSuccess]);

  useEffect(() => {
    if (isGetBlocksError || isGetQuestionsError) {
      hint.error('Ошибка в получении данных');
    }
  }, [isGetBlocksError, isGetQuestionsError]);

  useEffect(() => {
    if (initialValues) {
      const blockIds = Object.keys(initialValues);
      setToLSWithOneDayExpiry(
        uid,
        blockIds.map((item) => `${uid}_block_${item}`),
      );

      const ids = getFromLocalStorage('interview', isArrayOfStrings);
      if (ids) {
        if (!ids.includes(uid)) {
          ids.push(uid);
          localStorage.setItem('interview', JSON.stringify(ids));
        }
      } else {
        localStorage.setItem('interview', JSON.stringify([uid]));
      }
    }
  }, [initialValues]);

  if (isGetBlocksError || isGetQuestionsError) {
    <Navigate to={routes.candidateProfile(uid)} />;
  }

  if (initialValues && isGetBlocksSuccess) {
    return (
      <div className={cx.wrapper}>
        <div className={cx.container}>
          <div className={cx.header}>
            <Title level={3}>Вопросы к соискателю</Title>
            <div className={cx.points_container}>
              <span className={cx.points_heading}>Итого:</span>
              <div className={cx.points}>
                {overallMark === null ? '-' : <span>{overallMark}%</span>}
              </div>
            </div>
            <div className={cx.line} />
          </div>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className={cx.form}
            initialValues={initialValues}
          >
            <div className={cx.blocks_container}>
              {blocks.map((block, i) => (
                <Block title={block.title} id={block.id} number={i + 1} key={block.id} />
              ))}
            </div>
            <div className={cx.button_container}>
              <div className={cn(cx.line, cx.lineTop)} />
              <Button type="primary" htmlType="button" onClick={handleSubmit} loading={isLoading}>
                Завершить тестирование
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
  return <Loader />;
};
