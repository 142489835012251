import React from 'react';
import { Badge } from 'antd';
import cn from 'classnames';

import { ReactComponent as NotificationSvg } from 'assets/images/svg/notification.svg';

import cx from './index.module.scss';

type TNotificationsProps = {
  className?: string;
};

export const Notifications = ({ className }: TNotificationsProps) => (
  <Badge dot className={cn(cx.badge, className)}>
    <NotificationSvg className={cx.icon} />
  </Badge>
);
