export enum EEnvironment {
  DEVELOPMENT = 'development',
  DEVELOP = 'develop',
  STAGE = 'stage',
  MASTER = 'master',
}

export enum EBackendRoutes {
  DEVELOP = 'https://skillas-back.lad-academy.ru',
  STAGE = 'https://skillas-back.lad-academy.ru',
  MASTER = 'https://skillas-back.lad-academy.ru',
}

export enum EApiTags {
  COMMENT_ABOUT_CANDIDATE = 'Comment',
  CANDIDATE = 'Candidate',
  QUESTION = 'Question',
  DIRECTIONS = 'Directions',
  VACANCY = 'Vacancy',
  INTERVIEW = 'Interview',
  TECH_TASK_SOLUTION = 'Tech task solution',
}

export const tagArray = Object.values(EApiTags);
