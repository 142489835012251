import React, { useEffect, useState } from 'react';

import { Logo } from 'components/Logo';
import { Button } from 'components/ui/Button';
import { ModalWarning } from 'components/ui/Modal';

import { useTimer } from './useTimer';
import { formatMsTo } from 'utils/dateUtils';
import cn from 'classnames/bind';

import styles from './index.module.scss';

const cx = cn.bind(styles);

type TProps = {
  startedAt?: number;
  endedAt?: number;
  timeLimit?: number;
  completeTask: () => void;
};

export const HeaderTesting = (props: TProps) => {
  const { startedAt, completeTask, endedAt, timeLimit } = props;
  const isEndTask = !!endedAt;
  const timeFromTimer = useTimer(isEndTask, startedAt);
  const timer = isEndTask && startedAt ? endedAt - startedAt : timeFromTimer;
  const overLimit = !!timeLimit && timer >= timeLimit - 1000;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    // if (overLimit) completeTask(); // FIXME если по этому событию ничего не придумают
  }, [overLimit]);

  const handleModalOk = () => {
    completeTask();
    setIsModalOpen(false);
  };

  return (
    <div className={styles.header_testing_wrapper}>
      <div className={styles.logo_container}>
        <Logo type="blue" />
      </div>
      {!!startedAt && !endedAt && (
        <>
          <div className={cx('timer', { overlimit: isEndTask || overLimit })}>
            {formatMsTo(timer, 'HH:mm:ss')}
          </div>
          <Button onClick={() => setIsModalOpen(true)} fixed type="primary" disabled={isEndTask}>
            Завершить
          </Button>
          <ModalWarning
            visible={isModalOpen}
            handleOk={handleModalOk}
            handleCancel={() => setIsModalOpen(false)}
            contentText="Вы уверены, что хотите завершить выполнение задания?"
            cancelText="Нет"
            okText="Да"
            type="attention"
          />
        </>
      )}
    </div>
  );
};
