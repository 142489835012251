import React from 'react';
import { Typography } from 'antd';

import { ReactComponent as AuthPictureSvg } from 'assets/images/svg/auth_picture.svg';
import { Logo } from 'components/Logo';
import LoginForm from './LoginForm';

import cx from './index.module.scss';

const { Title } = Typography;

export const AuthContainer = () => (
  <div className={cx.layout}>
    <div className={cx.side_wrapper}>
      <Logo className={cx.logo}
            type='blue' />
      <AuthPictureSvg className={cx.picture} />
    </div>
    <div className={cx.main_wrapper}>
      <Logo className={cx.logo}
            type='blue' />
      <div className={cx.form}>
        <Title className={cx.title}
               level={2}>
          Авторизация
        </Title>
        <LoginForm />
      </div>
      <div className={cx.copyright}>
        <span>Powered by <a href='https://lad-academy.ru/'>Lad academy</a></span>
        <span>© 2022-2023, Нижний Новгород</span>
      </div>
    </div>
  </div>
);
