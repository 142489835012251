import React from 'react';
import cn from 'classnames/bind';

import styles from './index.module.scss';

const cx = cn.bind(styles);

type TProps = {
  classItem: string;
};

export const ProgressItem = (props: TProps) => {
  const { classItem } = props;
  return <div className={cx(styles.progress_item, styles[classItem])} />;
};
