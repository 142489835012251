import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { InterviewForm } from '../InterviewElements/InterviewForm';
import { ModalWarning } from 'components/ui/Modal';

import { hint } from 'components/ui/Hint';
import { TRouterParams } from 'routing/types';
import { useAppDispatch } from 'store/hooks';
import { setPartialSubmitCancelled } from 'store/interview/slice';
import { TInterviewFormValues } from 'store/interview/types';
import { ECustomValidationStatuses } from '../InterviewElements/InterviewForm/customFormValidation';
import {
  useCreateCustomQuestionsMutation,
  useDeleteCustomQuestionsMutation,
  usePostInterviewResultMutation,
} from 'store/interview';
import { processResultsData } from './interviewResultsProcessing';
import { clearInerviewFromLSAfterSending } from 'utils/localStorage/clearExpiredInterviews';
import { routes } from 'routing/routes';

const modalMessages: Record<ECustomValidationStatuses, string> = {
  [ECustomValidationStatuses.FULL]: 'Вы действительно хотите завершить тестирование?',
  [ECustomValidationStatuses.FULL_EMPTY]:
    'Вы не заполнили форму. Вы действительно хотите завершить тестирование?',
  [ECustomValidationStatuses.PARTIAL_EMPTY]:
    'Вы не заполнили все обязательные поля. Вы действительно хотите завершить тестирование?',
};

export const Interview = () => {
  const dispatch = useAppDispatch();
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const [modalStatus, setModalStatus] = useState<ECustomValidationStatuses | false>(false);
  const [values, setValues] = useState<TInterviewFormValues>();
  const [
    sendInterviewResults,
    {
      isLoading: isInterviewResultsLoading,
      isError: isInterviewResultsError,
      isSuccess: isInterviewResultsSuccess,
    },
  ] = usePostInterviewResultMutation();
  const [deleteCustomQuestions] = useDeleteCustomQuestionsMutation();
  const [
    createCustomQuestions,
    { isError: isCreateCustomError, isLoading: isCreateCustomLoading },
  ] = useCreateCustomQuestionsMutation();

  useEffect(() => {
    if (isInterviewResultsSuccess) {
      hint.success('Интервью успешно отправлено');
    }
  }, [isInterviewResultsSuccess]);

  useEffect(() => {
    if (isCreateCustomError || isInterviewResultsError) {
      if (isInterviewResultsError) {
        deleteCustomQuestions(uid);
      }
      hint.error('Ошибка отправки данных на сервер');
    }
  }, [isCreateCustomError, isInterviewResultsError]);

  useEffect(() => {
    if (isInterviewResultsSuccess) {
      clearInerviewFromLSAfterSending(uid);
    }
  }, [isInterviewResultsSuccess]);

  const handleOk = async () => {
    setModalStatus(false);
    if (modalStatus === ECustomValidationStatuses.FULL_EMPTY) {
      sendInterviewResults({ body: [], uid });
    } else if (values) {
      const { customQuestions, customQuestionsResults, resultQuestions } = processResultsData(
        values,
        uid,
      );

      if (customQuestions.length) {
        try {
          const customQuestionsIds = await createCustomQuestions(customQuestions).unwrap();
          for (let i = 0; i < customQuestions.length; i += 1) {
            const customQuestionId = customQuestionsIds[i].id;
            resultQuestions.push({
              question: customQuestionId,
              ...customQuestionsResults[i],
            });
          }
        } catch (err) {
          return;
        }
      }
      sendInterviewResults({ body: resultQuestions, uid });
    }
  };

  const handleCancel = () => {
    setModalStatus(false);
    if (modalStatus === ECustomValidationStatuses.PARTIAL_EMPTY) {
      dispatch(setPartialSubmitCancelled());
    }
  };

  const onFinishInterview = (values: TInterviewFormValues, status: ECustomValidationStatuses) => {
    setValues(values);
    setModalStatus(status);
  };

  if (isInterviewResultsSuccess) {
    return <Navigate to={routes.candidateProfile(uid)} />;
  }
  return (
    <>
      <InterviewForm
        onFinish={onFinishInterview}
        isLoading={isCreateCustomLoading || isInterviewResultsLoading}
      />
      <ModalWarning
        destroyOnClose
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={Boolean(modalStatus)}
        closable={false}
        width={627}
        footer={false}
        okText="Завершить"
        cancelText="Остаться"
        type="attention"
        contentText={modalStatus ? modalMessages[modalStatus] : ''}
      />
    </>
  );
};
