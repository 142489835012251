export const AUTH_SLICE = 'auth' as const;

export type TAuth = {
  loginError: { message: string; type: 'server' | 'validation' | 'invalid data' } | null;
  isAuth: boolean
};

export type TLoginUser = {
  email: string;
  password: string;
};

export type TLoginUserResponse = {
  token: string;
};

export type TRejectValue = {
  statusCode: number;
  message: string;
  error: string;
}
