import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LockSvg } from 'assets/images/svg/Lock.svg';
import { ReactComponent as UserSvg } from 'assets/images/svg/User.svg';
import { ReactComponent as EyeOpenedSvg } from 'assets/images/svg/Open-Eye.svg';
import { ReactComponent as EyeClosedSvg } from 'assets/images/svg/Closed-Eye.svg';
import { ReactComponent as ClearFieldSvg } from 'assets/images/svg/Close.svg';
import { Button } from 'components/ui/Button';

import { useLoginMutation } from 'store/auth';
import { setLoginValidationError } from 'store/auth/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TLoginUser } from 'store/auth/types';
import { routes } from 'routing/routes';
import { rules } from 'constants/inputRules';
import { getLoginError } from 'store/auth/selectors';

import cx from './index.module.scss';

const passwordEyeChange = (visible: boolean) =>
  visible ? <EyeOpenedSvg className={cx.eye_opened} /> : <EyeClosedSvg className={cx.eye_closed} />;

const LoginForm = () => {
  const navigate = useNavigate();
  const error = useAppSelector(getLoginError);
  const dispatch = useAppDispatch();
  const [login, { isLoading, isSuccess }] = useLoginMutation();

  const trimLoginValues = (values: TLoginUser) => {
    values.email = values.email.trim();
    values.password = values.password.trim();
    return values;
  };
  const onFinishFailed = () => {
    dispatch(setLoginValidationError('Заполните все поля'));
  };
  const onFinish = (values: TLoginUser) => {
    values.email = values.email.toLowerCase();
    login(trimLoginValues(values));
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(routes.root);
    }
  }, [isSuccess]);

  return (
    <Form
      name='register'
      onFinishFailed={onFinishFailed}
      onFinish={onFinish}
      className={cx.form}
      scrollToFirstError
      autoComplete='off'
    >
      <Form.Item
        name='email'
        rules={[rules.required]}
        className={cx.input_email}
        validateStatus={error && error.type === 'invalid data' ? 'error' : undefined}
      >
        <Input
          size='large'
          prefix={<UserSvg />}
          placeholder='Логин'
          maxLength={320}
          allowClear={{ clearIcon: <ClearFieldSvg className={cx.clear_field_svg} /> }}
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[rules.required]}
        className={cx.input_password}
        validateStatus={error && error.type === 'invalid data' ? 'error' : undefined}
      >
        <Input.Password
          size='large'
          prefix={<LockSvg />}
          placeholder='Пароль'
          maxLength={100}
          iconRender={passwordEyeChange}
        />
      </Form.Item>
      <Button block type='primary' htmlType='submit' loading={isLoading}>
        Войти
      </Button>
      {error && <span className={cx.login_form_error}>{error.message}</span>}
    </Form>
  );
};
export default LoginForm;
