import React from 'react';
import { ReactComponent as IconSuccess } from 'assets/images/svg/verify-circle.svg';

import cx from './index.module.scss';

export const CompleteTask = () => (
  <div className={cx.wrapper}>
    <section className={cx.container}>
      <header className={cx.icon}>
        <IconSuccess />
      </header>
      <main className={cx.main}>
        <h4 className={cx.title}>Задание успешно отправлено!</h4>
        <div className={cx.content}>
          Задание отправлено на проверку нашему самому внимательному сотруднику, надеемся он не
          заскучает
        </div>
      </main>
    </section>
  </div>
);
