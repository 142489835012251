import currentBranch from 'environment';
import { EBackendRoutes, EEnvironment } from './types';

export const url = () => {
  // если у нас запущен проект локально, то всегда смотрим на
  // dev контур бэка
  if (process.env.NODE_ENV === EEnvironment.DEVELOPMENT) {
    return EBackendRoutes.DEVELOP;
  }

  // Проверим, не на верселе ли у нас деплой
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === EEnvironment.DEVELOP) {
    return EBackendRoutes.DEVELOP;
  }
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === EEnvironment.STAGE) {
    return EBackendRoutes.STAGE;
  }
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === EEnvironment.MASTER) {
    return EBackendRoutes.MASTER;
  }

  // если у нас был билд, но мы хостимся сами где-нить на VPS,
  // то смотрим, из какой ветки он был сделан и
  // в зависимости от этого берем нужный url бэка
  if (currentBranch === EEnvironment.MASTER) {
    return EBackendRoutes.MASTER;
  }
  if (currentBranch === EEnvironment.STAGE) {
    return EBackendRoutes.STAGE;
  }
  return EBackendRoutes.DEVELOP
}
