import React from 'react';

import { Typography } from 'antd';

const { Paragraph } = Typography;

export const InstructionText = () => (
  <>
    <Paragraph>
      <br />К выполнению тестового задания вы можете приступить <b>только один раз</b> (даётся одна
      попытка). Войти в тестовое задание можно с любого компьютера, имеющего устойчивый доступ в
      сеть “Интернет”. Повторное тестирование не допускается.
    </Paragraph>
    <Paragraph>
      С начала тестирования будет включен секундомер, который будет считать время написания кода. Вы
      пишите код в режиме реального времени, все ваши действия фиксируются.
    </Paragraph>
    <Paragraph>
      Будет оценена скорость вашей работы, идеи и реализация, ошибки и исправления.
    </Paragraph>
    <Paragraph>
      Не рекомендуется вставлять готовые куски кода, пользоваться интернет-источниками.
    </Paragraph>
    <Paragraph>
      Вы можете приступить к выполнению тестового задания в любое удобное время.
    </Paragraph>
    <Paragraph>
      <b> Желаем удачи! </b>
    </Paragraph>
  </>
);
