import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { Button } from 'components/ui/Button';
import { ReactComponent as MainPageSvg } from 'assets/images/svg/main_page_picture.svg';

import { routes } from 'routing/routes';

import cx from './index.module.scss';

const { Title } = Typography;

export const MainPage = () => (
  <div className={cx.wrapper}>
    <div className={cx.container}>
      <div className={cx.content}>
        <Title level={1}
               className={cx.title}
        >
          Добро пожаловать
          на платформу <span className={cx.logo}>Hardskills</span>
        </Title>
        <p className={cx.subtitle}>
          Комплексное решение, которое поможет эффективнее оценивать и выбирать лучшего кандидата для должности.
        </p>
        <ul className={cx.list}>
          <li>Онлайн-фиксация ответов на этапе интервью</li>
          <li>Удобное отслеживание тестового задания</li>
          <li>Быстрое сравнение кандидатов</li>
        </ul>
        <Link to={routes.newCandidate}>
          <Button type='primary'
                  className={cx.button}
          >
            Начать работу
          </Button>
        </Link>
      </div>
      <MainPageSvg className={cx.picture} />
    </div>
  </div>
);
