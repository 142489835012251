import React from 'react';
import { ButtonProps } from 'antd';
import cn from 'classnames';

import { Button } from 'components/ui/Button';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CandidatesListActions } from 'store/candidatesList/slice';
import { getCandidatesListActiveFiltersCount } from 'store/candidatesList/selectors';

type TResetButtonProps = ButtonProps & {
  activeClassName: string;
}

export const ResetButton = ({ className, activeClassName }: TResetButtonProps) => {
  const dispatch = useAppDispatch();
  const filtersCount = useAppSelector(getCandidatesListActiveFiltersCount);

  const onClick = () => {
    dispatch(CandidatesListActions.resetAllFilters());
  };

  return (
    <Button className={cn(className, { [`${activeClassName}`]: filtersCount })}
            type='link'
            onClick={onClick}
    >
      Сбросить всё
    </Button>
  );
};