import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { isArrayOfStrings, isQuestionObject } from 'utils/typeGuards';
import { INTERVIEW_SLICE, TInterview, TInterviewBlock, TInterviewQuestion, TQuestion, TQuestionMark } from './types';

const initialState: TInterview = {
    score: {},
    isPartialSubmitCancelled: false,
    questions: {},
    initialInterviewValues: null,
};

export const interviewSlice = createSlice({
    name: INTERVIEW_SLICE,
    initialState,
    reducers: {
        clearInterview: () => initialState,
        setQuestionScore: (state, action: PayloadAction<TQuestionMark>) => {
            state.score[action.payload.name] = action.payload.value;
        },
        setPartialSubmitCancelled: (state) => {
            state.isPartialSubmitCancelled = true;
        },
        setQuestionIsChosen: (state, { payload }: PayloadAction<{ blockId: string, questionId: string }>) => {
            state.questions[payload.blockId][payload.questionId].chosen = true;
        },
        prepareQuestionData: (state, { payload }: PayloadAction<{ questions: TQuestion[], blocks: TInterviewBlock[], uid: string }>) => {
            const { questions, blocks, uid } = payload;
            const initialBlockQuestionCount = [5, 2, 3];
            const blocksQuestionCount: { [blockId: string]: number } = {};
            const chosenQuestionsIds: { [blockId: string]: string[] } = {};
            const blockIdArray = blocks.map((block, i) => {
                blocksQuestionCount[block.id] = initialBlockQuestionCount[i];
                state.questions[block.id] = {};
                chosenQuestionsIds[block.id] = [];
                return block.id;
            });

            questions.forEach((q) => {
                if (blockIdArray.includes(q.blockId)) {
                    if (blocksQuestionCount[q.blockId] > 0) {
                        blocksQuestionCount[q.blockId] -= 1;
                        chosenQuestionsIds[q.blockId].push(`${uid}_${q.id}`);
                    }
                    state.questions[q.blockId][q.id] = { chosen: false, text: q.text };
                }
            })

            const blocksCount = blocks.length;
            state.initialInterviewValues = {};

            for (let i = 0; i < blocksCount; i += 1) {
                const isTechQestion = i === 0;
                const blockId = blocks[i].id;
                state.initialInterviewValues[blockId] = [];
                const chosenBlockQuestions = chosenQuestionsIds[blockId];
                const unifiedQuestionIds: string[] = [...chosenBlockQuestions];
                const questionIdsFromLS = getFromLocalStorage(`${uid}_block_${blockId}`, isArrayOfStrings);

                /*  для каждого блока создаём массив unifiedQuestionsIds, где храняться id стартовых(дэфолтных) вопросов 
                 с бэка(chosenBlockQuestions) и id вопросов из LocalStorage(questionIdsFromLS). Чтобы они не повторялись, делаем фильтр */
                if (questionIdsFromLS) {
                    unifiedQuestionIds.push(...questionIdsFromLS.filter((item) => !chosenBlockQuestions.includes(item)));
                }

                for (let j = 0; j < unifiedQuestionIds.length; j += 1) {
                    /* проходимся по массиву с id вопросов. сначала смотрим в LS, потому что там могут находиться данные
                    если данных нет, делаем до проверку на то, что id пришёл с бэка и добавляем объект */
                    const question = getFromLocalStorage(unifiedQuestionIds[j], isQuestionObject(isTechQestion));
                    if (question) {
                        state.initialInterviewValues[blockId].push(question);
                        if (!question.custom) {
                            state.questions[blockId][unifiedQuestionIds[j].split("_")[1]].chosen = true;
                        }
                        if (isTechQestion && question.score !== undefined) {
                            state.score[question.id] = question.score;
                        }
                    } else if (chosenBlockQuestions.includes(unifiedQuestionIds[j])) {
                        const questionId = unifiedQuestionIds[j].split("_")[1];
                        const questionText = state.questions[blockId][questionId].text;
                        const question: TInterviewQuestion = { id: questionId, comment: "", question: questionText, custom: false };
                        state.questions[blockId][questionId].chosen = true;
                        if (isTechQestion) {
                            question.score = null;
                            state.score[question.id] = question.score;
                        }
                        state.initialInterviewValues[blockId].push(question);
                    }
                }

            }


        }
    },
});

export const { setQuestionScore, setPartialSubmitCancelled, prepareQuestionData, setQuestionIsChosen, clearInterview } = interviewSlice.actions;
export default interviewSlice.reducer;