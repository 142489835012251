import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { hint } from 'components/ui/Hint';
import { routes } from 'routing/routes';

export const ErrorCandidate = () => {
  useEffect(() => {
    hint.error('Ошибка в получении данных о кандидате');
  }, []);

  return <Navigate to={routes.candidatesList} />;
};
