import React from 'react';
import { useGetCandidateByIdQuery } from 'store/candidate';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

export const CandidateName = ({ match }: BreadcrumbComponentProps) => {
  const id = match.params.candidateId as string;
  // хук если просто ссылка пришла, а если перешли из списка, тоесть есть список, то надо из списка брать имя. Реализовать позже. Почему? Просто если будет ошибка запроса то в хлебных крошках не будет имени. Вроде бы мелочь
  const { data: candidate } = useGetCandidateByIdQuery(id);
  return <span>{candidate ? `${candidate.lastName} ${candidate.firstName}` : ''}</span>;
};
