import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';

import { logout } from 'store/auth/slice';
import { useAppDispatch } from 'store/hooks';

import cx from './index.module.scss';

export const ExitButton = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(logout());
  };

  return (
    <button type="button" onClick={handleClick} className={cx.button}>
      <LogoutOutlined />
      Выход
    </button>
  );
};
