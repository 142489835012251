import React, { FC } from 'react';

import { EEventDescription, TStampEvent } from 'store/techTask/types';

import style from './index.module.scss';

type TEventDescription = {
  eventType: TStampEvent;
};

export const EventDescription: FC<TEventDescription> = ({ eventType }) => {
  const type = EEventDescription[eventType.toUpperCase()];

  return (
    <div className={style.description}>
      <span>Событие:</span>
      <span>{type}</span>
    </div>
  );
};
