import { api } from 'network/api/api';
import { EApiTags } from 'network/api/types';
import { apiRoutes } from 'network/apiRoutes';
import { TDirections } from './types';

export const directionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDirections: builder.query<TDirections[], void>({
      query: () => ({
        url: `${apiRoutes.DIRECTION}`,
        method: 'GET',
      }),
      providesTags: [EApiTags.DIRECTIONS],
    }),
    addDirection: builder.mutation<TDirections[], Partial<TDirections>>({
      query: (body) => ({
        url: `${apiRoutes.DIRECTION}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EApiTags.DIRECTIONS],
    }),
    getDirectionById: builder.query<TDirections, number>({
      query: (id) => ({
        url: `${apiRoutes.DIRECTION}/${id}`,
        method: 'GET',
      }),
      providesTags: [EApiTags.DIRECTIONS],
    }),
    editDirectionById: builder.mutation<TDirections, Partial<TDirections>>({
      query: ({ id, ...body }) => ({
        url: `${apiRoutes.DIRECTION}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EApiTags.DIRECTIONS],
    }),
  }),
});

export const { useGetAllDirectionsQuery } = directionApi;