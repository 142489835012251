import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CodeEditor } from 'components/ui/CodeEditor';

import { useParams } from 'react-router-dom';
import { TRouterParams } from 'routing/types';

import { Timeline } from './Timeline';
import { CodePlayer } from './CodePlayer';
import { EventDescription } from './EventDescription';
import { Loader } from 'components/ui/Loader';

import { useGetTaskStampsQuery } from 'store/techTask';
import { TTechTaskStamp } from 'store/techTask/types';

import style from './index.module.scss';

export const TechTaskWatch = () => {
  const { solutionId: id } = useParams<keyof TRouterParams>() as TRouterParams;
  const { data: stamps, isLoading } = useGetTaskStampsQuery(id);
  // const [solution, setSolution] = useState<TTechTaskStamp[] | undefined>(data);
  const [value, setValue] = useState<string>('');
  const [currentMark, setCurrentMark] = useState<number>(0);
  const [isPause, setIsPause] = useState<boolean>(true);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const timerRef = useRef<any>(null);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLOptionElement>) => {
      const { value } = e.currentTarget;
      if (stamps) {
        const currentMarkIndex = stamps.findIndex((stamp) => stamp.createdAt === +value);
        setCurrentMark(currentMarkIndex);
        setValue(stamps[currentMarkIndex].code);
        setIsPause(true);
      }
    },
    [currentMark],
  );

  const handleChange = useCallback((value: number) => {
    clearTimeout(timerRef.current);
    setIsPause(true);
    setCurrentMark(value);
  }, []);

  const handleRewind = (type: string) => {
    if (type === 'prev' && currentMark > 0) {
      setCurrentMark(currentMark - 1);
    }
    if (type === 'next' && stamps && currentMark < stamps.length - 1) {
      setCurrentMark(currentMark + 1);
    }
  };

  const autoplay = (counter: number, speed: number, pause: boolean) => {
    if (stamps && counter < stamps.length && !pause) {
      const timer = setTimeout(() => {
        setCurrentMark(counter);
        counter += 1;
        autoplay(counter, speed, pause);
      }, 100);
      timerRef.current = timer;
    }
    if (stamps && counter === stamps.length) {
      setIsPause(true);
    }
  };

  const handlePlay = () => {
    if (stamps && currentMark === stamps.length - 1) {
      setCurrentMark(0);
    }
    setIsPause(!isPause);
  };

  useEffect(() => {
    if (isPause) {
      clearInterval(timerRef.current);
    }
  }, [timerRef.current]);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      autoplay(currentMark, 5, isPause);
    }, 500);
    if (isPause) {
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [isPause]);

  useEffect(() => {
    if (stamps && stamps.length > 2) {
      setCurrentTime(stamps[currentMark].createdAt - stamps[0].createdAt);
    }
    if (stamps) {
      setValue(stamps[currentMark].code);
    }
  }, [currentMark]);

  useEffect(() => {
    if (stamps) {
      setTotalTime(
        stamps && stamps.length > 2 ? stamps[stamps.length - 1].createdAt - stamps[0].createdAt : 0,
      );
    }
  }, [stamps]);

  if (isLoading) return <Loader />;

  return (
    <div className={style.content}>
      {stamps && (
        <>
          <CodePlayer
            max={stamps.length - 1}
            currentTime={currentTime}
            totalTime={totalTime}
            handleChange={handleChange}
            currentMark={currentMark}
            handleRewind={handleRewind}
            handlePlay={() => handlePlay()}
            isPause={isPause}
          />

          <Timeline marks={stamps} currentMark={currentMark} onClick={(e) => handleClick(e)} />
          <EventDescription eventType={stamps[currentMark]?.eventName || ''} />
          <CodeEditor value={value || ''} handleChange={() => null} disabled />
        </>
      )}
    </div>
  );
};
