import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Divider, Form, Input, Popconfirm, Row, Select, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/ui/Button';
import { ModalWarning, TModalWarningProps } from 'components/ui/Modal';

import { CandidateFormRules } from './inputRules';
import { TCandidate, TFormValues } from 'store/candidate/types';
import { LEVELS } from 'constants/level';
import { STAGES } from 'constants/stageProgress';
import { routes } from 'routing/routes';

import { BaseSelectRef } from 'rc-select';
import { useGetAllDirectionsQuery } from 'store/directions';
import { useAddVacancyMutation, useGetAllVacanciesQuery } from 'store/vacancy';

import style from './index.module.scss';

const { Option } = Select;
const { Title } = Typography;

type TCandidateForm = {
  onFinish: (values: TFormValues) => void;
  loading: boolean;
  edit?: boolean;
  initialState?: Partial<TCandidate>;
};

export const CandidateForm: FC<TCandidateForm> = ({ onFinish, loading, edit, initialState }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const vacancyRef = useRef<BaseSelectRef>(null);
  const [newVacancyName, setNewVacancyName] = useState<string>();
  const [form] = useForm();
  const { data: directions } = useGetAllDirectionsQuery();
  const { data: allVacancy } = useGetAllVacanciesQuery();
  const [addNewVacancy, response] = useAddVacancyMutation();
  const stages = STAGES;
  const levels = LEVELS;
  const { surname, name, middlename, phone, email, job, direction, level } = CandidateFormRules;
  const selectOptions = allVacancy?.map((vacancy) =>
    Object.fromEntries(
      Object.entries(vacancy).map(([key, value]) => [key === 'name' ? 'label' : 'value', value]),
    ),
  );

  const editPopconfimText =
    'Вы уверены, что хотите отменить редактирование формы кандидата? Внесенные изменения будут утеряны.';
  const createPopconfirmText =
    'Вы уверены, что хотите очистить форму регистрации? Внесенные изменения будут утеряны.';

  const popConfirmTitle = edit ? editPopconfimText : createPopconfirmText;

  const title = edit ? 'Редактирование информации о кандидате' : 'Добавление нового кандидата';

  const normalizeInitialState = (initialState: Partial<TCandidate>) => {
    const normalized = Object.fromEntries(
      Object.entries(initialState).map(([key, value]) => {
        if (value === initialState.direction) {
          return ['directionId', value.id, value.title];
        }
        if (value === initialState.vacancy) {
          return [key, value.id, value.name];
        }
        return [key, value];
      }),
    );

    return normalized as TFormValues;
  };
  const normalizeSendValues = (values: TFormValues) => {
    const normalized = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (!value && edit) {
          return [key, null];
        }
        if (!value) {
          return [key, undefined];
        }
        return [key, value];
      }),
    );
    return normalized as TFormValues;
  };

  const handleAddVacancy = () => {
    addNewVacancy({ name: newVacancyName });
  };

  const clearValues = () => {
    if (edit) {
      form.setFieldsValue(initialState ? normalizeInitialState(initialState) : null);
    } else {
      form.resetFields();
    }
  };

  const trimValues = () => {
    const values = form.getFieldsValue();
    const trimmedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        typeof value === 'string' ? value.trim() : value,
      ]),
    );
    form.validateFields();
    form.setFieldsValue(trimmedValues);
  };

  const sendValues = () => {
    trimValues();
    const values: TFormValues = form.getFieldsValue();
    onFinish(normalizeSendValues(values));
  };

  const inputColSpan = {
    xs: {
      span: 22,
    },
    sm: {
      span: 12,
    },
    md: {
      span: 10,
    },
    lg: {
      span: 6,
    },
  };

  const modalErrorProps: TModalWarningProps = {
    handleOk() {
      setIsModalOpen(false);
      clearValues();
      if (!edit) navigate(routes.root);
    },
    handleCancel() {
      setIsModalOpen(false);
    },
    contentText: edit
      ? 'Вы уверены, что хотите отменить редактирование?'
      : 'Вы уверены, что хотите отменить добавление кандидата?',
    cancelText: 'Остаться',
    okText: 'Отменить',
    type: 'attention',
  };

  useEffect(() => {
    if (initialState) {
      form.setFieldsValue(normalizeInitialState(initialState));
    }
  }, [initialState]);
  useEffect(() => {
    if (response && response.status === 'fulfilled') {
      form.setFields([{ name: ['vacancy'], value: response.data.id }]);
    }
  }, [response]);

  return (
    <div className={style.form_wrapper}>
      <Title level={3} className={style.page_title}>
        <Col offset={1}>{title}</Col>
      </Title>
      <Form
        form={form}
        name='newCandidateForm'
        layout='vertical'
        onFinish={sendValues}
        autoComplete='off'
        scrollToFirstError
        initialValues={initialState}
      >
        <Col span={24}>
          <Title level={5} className={style.section_title}>
            <Col offset={1}>Личная информация</Col>
          </Title>
        </Col>
        <Col offset={1}>
          <Space direction='vertical' size='large' style={{ display: 'flex' }}>
            <Row gutter={[24, 24]}>
              <Col {...inputColSpan} className={style.input_col}>
                <FormItem
                  name='lastName'
                  label='Фамилия'
                  rules={surname}
                  validateTrigger='onBlur'
                  validateFirst
                >
                  <Input size='large' placeholder='Иванов' maxLength={51} />
                </FormItem>
              </Col>
              <Col {...inputColSpan} className={style.input_col}>
                <FormItem
                  name='firstName'
                  label='Имя'
                  rules={name}
                  validateTrigger='onBlur'
                  validateFirst
                >
                  <Input size='large' placeholder='Иван' maxLength={51} />
                </FormItem>
              </Col>
              <Col {...inputColSpan} className={style.input_col}>
                <FormItem
                  name='middleName'
                  label='Отчество'
                  rules={middlename}
                  validateTrigger='onBlur'
                  validateFirst
                >
                  <Input size='large' placeholder='Иванович' maxLength={51} />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col {...inputColSpan} className={style.input_col}>
                <FormItem name='phone' label='Телефон' rules={phone} validateTrigger='onBlur'>
                  <Input maxLength={12} size='large' placeholder='+79XXXXXXXXX' />
                </FormItem>
              </Col>
              <Col {...inputColSpan} className={style.input_col}>
                <FormItem
                  name='email'
                  label='E-mail'
                  rules={email}
                  validateFirst
                  validateTrigger='onBlur'
                >
                  <Input size='large' placeholder='Введите почту' />
                </FormItem>
              </Col>
            </Row>
          </Space>
        </Col>
        <Divider className={style.divider} />
        <Col span={24}>
          <Title level={5} className={style.section_title}>
            <Col offset={1}>Информация о должности</Col>
          </Title>
        </Col>
        <Col offset={1}>
          <Space direction='vertical' size='large' style={{ display: 'flex' }}>
            <Row gutter={[24, 24]}>
              <Col {...inputColSpan} className={style.input_col}>
                <Form.Item name='vacancy' label='Вакансия' rules={job} validateTrigger='onBlur'>
                  <Select
                    ref={vacancyRef}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    listHeight={131}
                    dropdownMatchSelectWidth
                    size='large'
                    placeholder='Выберите вакансию'
                    allowClear
                    options={selectOptions}
                    onSearch={(value) => setNewVacancyName(value)}
                    notFoundContent={
                      <button
                        type='button'
                        className={style.add_vacancy_button}
                        onClick={handleAddVacancy}
                      >
                        + Добавить вакансию
                      </button>
                    }
                  />
                </Form.Item>
              </Col>
              <Col {...inputColSpan} className={style.input_col}>
                <Form.Item
                  name='directionId'
                  label='Направление'
                  rules={direction}
                  validateTrigger='onBlur'
                >
                  <Select
                    listHeight={131}
                    dropdownMatchSelectWidth
                    size='large'
                    placeholder='Выберите направление'
                    allowClear
                  >
                    {directions?.map((direction) => (
                      <Option value={direction.id} key={direction.id}>
                        {direction.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {!edit ? (
                <Col {...inputColSpan} className={style.input_col}>
                  <Form.Item name='level' label='Уровень' rules={level} validateTrigger='onBlur'>
                    <Select listHeight={131} size='large' placeholder='Выберите уровень' allowClear>
                      {levels?.map((level) => (
                        <Option value={level} key={level}>
                          {level}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            {edit && (
              <Row gutter={[24, 24]}>
                <Col {...inputColSpan} className={style.input_col}>
                  <Form.Item name='level' label='Уровень' rules={level} validateTrigger='onBlur'>
                    <Select listHeight={131} size='large' placeholder='Выберите уровень' allowClear>
                      {levels.map((level) => (
                        <Option value={level} key={level}>
                          {level}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...inputColSpan} className={style.input_col}>
                  <Form.Item name='stage' label='Этап отбора' validateTrigger='onBlur'>
                    <Select
                      listHeight={131}
                      size='large'
                      placeholder='Выберите этап отбора'
                      allowClear
                    >
                      {stages.map((stage) => (
                        <Option value={stage} key={stage}>
                          {stage}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Space>
        </Col>

        <Col offset={1}>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col>
              <Form.Item>
                <Button loading={loading} type='primary' htmlType='submit' fixed>
                  {edit ? 'Сохранить' : 'Создать'}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Popconfirm
                  overlayClassName={style.popconfirm_overlay}
                  title={popConfirmTitle}
                  onConfirm={clearValues}
                  okText='Да'
                  cancelText='Нет'
                >
                  <div>
                    <Button fixed>Отменить</Button>
                  </div>
                </Popconfirm>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
      <ModalWarning
        maskStyle={{ backgroundColor: '#bcbfcd99' }}
        visible={isModalOpen}
        {...modalErrorProps}
      />
    </div>
  );
};
