

// time все в милисекундах
export type TStampEvent = 'debounce' | 'blur' | 'focus' | 'paste';

export enum EEventDescription {
  DEBOUNCE = 'Набор кода',
  FOCUS = 'Возвращение на страницу тестирования',
  BLUR = 'Выход со страницы тестирования',
  PASTE = 'Вставка целых кусков кода',
}

type TSolutionID = string | number;

export type TTechTaskStamp = {
  id?: string;
  techSolutionId?: TSolutionID;
  createdAt: number;
  code: string;
  timeSincePrevStamp: number;
  eventName: TStampEvent;
  addingData?: string|number;
};

export type TTechTask = {
  id: number | string;
  title: string;
  description: string;
  timeLimit: number;
  createdAt: string;
};

export type TTechTaskSolution = {
  id: TSolutionID;
  startedAt?: string;
  endedAt?: string;
  techTaskId: TTechTask['id'];
  candidateId: number | string;
};

export type TNewTaskSolution = {
  techTaskId:string,
  candidateId:string
}
export type TDataForTechTask = {
  solution: TTechTaskSolution;
  task: TTechTask;
  lastStamp?: TTechTaskStamp;
};


