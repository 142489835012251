import { SorterResult } from 'antd/es/table/interface';

import { ECandidatesListOrderColumnName, TCandidatesListSortArgs } from 'store/candidatesList/types';
import { TCandidatesListTableRecord } from './types';

export const sortOrderAdapter = (sortOrder: SorterResult<TCandidatesListTableRecord>['order']): TCandidatesListSortArgs['order'] => {
  if (sortOrder === 'ascend') return 'ASC';
  if (sortOrder === 'descend') return 'DESC';
  return undefined;
};

export const sortColumnAdapter = (column: SorterResult<TCandidatesListTableRecord>['columnKey']): TCandidatesListSortArgs['orderColumnName'] => {
  if (
    column === ECandidatesListOrderColumnName.CANDIDATE_NAME ||
    column === ECandidatesListOrderColumnName.CREATED_AT ||
    column === ECandidatesListOrderColumnName.STAGE ||
    column === ECandidatesListOrderColumnName.INTERVIEW_SCORE ||
    column === ECandidatesListOrderColumnName.TECH_TASK_SCORE
  ) return column;
  // eslint-disable-next-line no-console
  console.error(`Unable to sort this column. Unknown column key: ${column}`);
  return undefined;
};