import React from 'react';

import { EStatus, EStage, STAGES } from 'constants/stageProgress';
import { ProgressItem } from './ProgressItem';
import { stageClassColor } from './adapters';

import cx from './index.module.scss';
import { Tooltip } from '../Tooltip';

type TProps = {
  currentStage: EStage;
  status: EStatus;
};

export const StageProgress = (props: TProps) => {
  const { currentStage, status } = props;

  const stages = Object.values(EStage);
  const indexCurrentStage = stages.findIndex((stage) => stage === currentStage);

  const getCssClass = (indexStage) => {
    if (indexStage <= indexCurrentStage) return stageClassColor[status];
    return '';
  };

  return (
    <div className={cx.wrapper_stage_progress}>
      <Tooltip title={currentStage} placement="bottomRight">
        <div className={cx.wrapper_progress_bar}>
          {STAGES.map((stage, indexStage) => (
            <ProgressItem key={stage} classItem={getCssClass(indexStage)} />
          ))}
        </div>
      </Tooltip>
    </div>
  );
};
