import React from 'react';
import { Typography } from 'antd';

import { ContactEmail } from './ContactEmail';

import cx from './index.module.scss';

const { Text } = Typography;

export type TContactsListItem = { icon: React.FC; content?: string; label: 'phone' | 'email' };

type TProps = { items: TContactsListItem[] };

export const ContactsList = ({ items }: TProps) => (
  <div>
    {items.map(
      (item) =>
        item.content && (
          <div className={cx.contacts_item} key={item.content}>
            {React.createElement(item.icon)}
            {item.label === 'email' ? (
              <ContactEmail contactString={item.content} />
            ) : (
              <Text className={cx.contact_text}>{item.content}</Text>
            )}
          </div>
        ),
    )}
  </div>
);
