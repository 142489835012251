export const wordScore = (n: number): string => {
  function score(n: number): string {
    if (n === 1) return 'балл';
    if (n > 1 && n < 5) return 'балла';
    if (n > 20) return score(n % 10);

    return 'баллов';
  }

  return `${n} ${score(n)}`;
};