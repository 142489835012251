import { RootState } from 'store';

const nullChecker = (value: number | null): value is number => value !== null

export const getOverallMark = (store: RootState) => {
    const values = Object.values(store.interview.score).filter(nullChecker);
    if (!values.length) {
        return null;
    }
    const result = (values.reduce((sum, curValue) => sum + curValue, 0) / (values.length * 5)) * 100;
    if (result % 1 === 0) {
        return result;
    }
    return Number(result.toFixed(1));
};
export const getPartialSubmitCancelledStatus = (store: RootState) => store.interview.isPartialSubmitCancelled;
export const getInterviewInitialValues = (store: RootState) => store.interview.initialInterviewValues;
export const getQuestions = (store: RootState) => store.interview.questions;
export const getQuestionsByBlockId = (blockId: string) => (store: RootState) => store.interview.questions[blockId];
