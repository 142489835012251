import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';

import { SelectingStatistic } from 'components/CandidateProfile/SelectingStatistic';
import { ResultsBlock } from 'components/CandidateProfile/ResultsBlock';
import { CommentsAboutCandidate } from 'components/CandidateProfile/CommentsAboutCandidate';
import { PersonalData } from 'components/CandidateProfile/PersonalData';
import { ErrorCandidate } from 'components/CandidateProfile/ErrorCandidate';
import { Loader } from 'components/ui/Loader';

import { TRouterParams } from 'routing/types';
import { useGetCandidateByIdQuery } from 'store/candidate';

import cx from './index.module.scss';

export const CandidateProfile = () => {
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const { isLoading, isError, isSuccess, isFetching } = useGetCandidateByIdQuery(uid);

  return (
    <>
      {isSuccess && (
        <Row className={cx.wrapper} wrap={false}>
          <Col className={cx.statistic_results}>
            <SelectingStatistic uid={uid} />
            <ResultsBlock uid={uid} />
          </Col>
          <Col className={cx.personal_and_comments}>
            <PersonalData uid={uid} />
            <CommentsAboutCandidate uid={uid} />
          </Col>
        </Row>
      )}
      {(isLoading || isFetching) && <Loader size="large" />}
      {isError && <ErrorCandidate />}
    </>
  );
};
