import React from 'react';
import { useParams } from 'react-router-dom';

import { TechTaskEditor } from './TechTaskEditor';

import { useGetDataForTechTaskQuery } from 'store/techTask';
import { TRouterParams } from 'routing/types';

export const TestingPageComponent = () => {
  const { solutionId: id } = useParams<keyof TRouterParams>() as TRouterParams;

  const { data, isError, isSuccess } = useGetDataForTechTaskQuery(id);
  return (
    <>
      {isError && <div>Или такого кандидата нет или задание ему еще не выдали</div>}
      {isSuccess && <TechTaskEditor {...data} />}
    </>
  );
};
