import React from 'react';
import { Empty } from 'antd';

import cx from './index.module.scss';

export const EmptyCommentsList = ({ isError }: { isError?: boolean }) => (
  <div className={cx.empty}>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        isError
          ? 'Не можем показать комментарии, нет связи с сервером'
          : 'Пока еще нет ни одного комментария'
      }
    />
  </div>
);
