import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Typography } from 'antd';

import cx from './index.module.scss';

const { Text } = Typography;

export const FullName = ({ fullName }: { fullName: Array<string | undefined> }) => (
  <div className={cx.fullname}>
    {fullName.map(
      (name) =>
        name && (
          <Text key={nanoid()} ellipsis>
            {name}
          </Text>
        ),
    )}
  </div>
);
