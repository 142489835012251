import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CandidateForm } from 'components/CandidateForm';

import { useAddCandidateMutation } from 'store/candidate';
import { TFormValues } from 'store/candidate/types';
import { ModalWarning, TModalWarningProps } from 'components/ui/Modal';
import { routes } from 'routing/routes';

export const NewCandidate: FC = () => {
  const [addCandidate, result] = useAddCandidateMutation();
  const { isLoading, isSuccess, isError } = result;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess || isError) {
      setIsModalOpen(true);
    }
  }, [isSuccess, isError]);

  const onFinish = (values: TFormValues) => {
    addCandidate(values);
  };

  const modalSuccessProps: TModalWarningProps = {
    handleOk() {
      setIsModalOpen(false);
      navigate(routes.candidateProfile(result?.data?.id));
    },
    handleCancel() {
      setIsModalOpen(false);
      navigate(routes.candidatesList);
    },
    contentText: 'Регистрация кандидиата прошла успешно!',
    cancelText: 'К списку кандидатов',
    okText: 'В карточку',
    type: 'success',
  };

  const modalErrorProps: TModalWarningProps = {
    handleOk() {
      setIsModalOpen(false);
      navigate(routes.root);
    },
    handleCancel() {
      setIsModalOpen(false);
    },
    contentText: 'Ошибка при обращении к серверу!',
    cancelText: 'Понятно',
    okText: 'На главную',
    type: 'error',
  };

  return (
    <>
      <CandidateForm onFinish={onFinish} loading={isLoading} />
      <ModalWarning visible={isModalOpen} {...(isSuccess ? modalSuccessProps : modalErrorProps)} />
    </>
  );
};
