import React from 'react';
import { Typography } from 'antd';

import { TStatisticItemType } from '..';

import cx from './index.module.scss';

type TProps = { items: TStatisticItemType[] };

const { Text } = Typography;

export const StatisticList = (props: TProps) => {
  const { items } = props;

  return (
    <div className={cx.wrapper_statistic_list}>
      {items.map(({ caption, content }) => (
        <React.Fragment key={caption}>
          <Text className={cx.item_caption}>{caption}</Text>
          <div className={cx.item_content}>{content}</div>
        </React.Fragment>
      ))}
    </div>
  );
};
