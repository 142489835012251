import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';

import App from 'App';
import { AppErrorBoundary } from 'containers/AppErrorBoundary';

import { store } from 'store';

import 'styles/index.scss';

moment.locale('ru');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppErrorBoundary>
      <Provider store={store}>
        <ConfigProvider locale={ruRU}>
          <App />
        </ConfigProvider>
      </Provider>
    </AppErrorBoundary>
  </React.StrictMode>,
);
