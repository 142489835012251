import React, { useState } from 'react';

import { StatisticList } from './StatisticList';
import { Loader } from 'components/ui/Loader';
import { StageProgress } from 'components/ui/StageProgress';
import { SelectStatus } from './SelectStatus';

import { useGetCandidateByIdQuery } from 'store/candidate';
import { TCandidate } from 'store/candidate/types';
import { formatDateDDMMYYYY } from 'utils/dateUtils';
import { EStatus } from 'constants/stageProgress';

import cx from './index.module.scss';

export type TStatisticItemType = {
  order: number;
  caption: string;
  content: React.ReactNode;
};

export const SelectingStatistic = ({ uid }: { uid: string }) => {
  const { data, isError, isLoading, isSuccess } = useGetCandidateByIdQuery(uid);

  const getStatistic = (candidate: TCandidate): TStatisticItemType[] => {
    const [status, setStatus] = useState<EStatus>(candidate.status);
    const { createdAt, stage } = candidate;

    return [
      {
        order: 1,
        caption: 'Дата создания',
        content: formatDateDDMMYYYY(createdAt),
      },
      {
        order: 2,
        caption: 'Балл за интервью',
        content: candidate.interviewScore,
      },
      {
        order: 3,
        caption: 'Балл за задание',
        content: candidate.techTaskScore,
      },
      {
        order: 4,
        caption: 'Этап отбора',
        content: <StageProgress currentStage={stage} status={status} />,
      },
      {
        order: 5,
        caption: 'Статус',
        content: <SelectStatus status={status} setStatus={setStatus} candidateId={uid} />,
      },
    ].sort((a, b) => a.order - b.order);
  };

  return (
    <div className={cx.wrapper}>
      {isSuccess && data && <StatisticList items={getStatistic(data)} />}
      {isLoading && <Loader />}
      {isError && <h3> Error</h3>}
    </div>
  );
};
