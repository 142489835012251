import { apiRoutes } from 'network/apiRoutes';
import { api } from 'network/api/api';

import { TCandidatesList, TCandidatesListQueryArgs } from './types';
import { EApiTags } from 'network/api/types';

export const candidatesListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCandidatesList: builder.query<TCandidatesList, Partial<TCandidatesListQueryArgs>>({
      query: (arg) => ({
        url: `${apiRoutes.CANDIDATES_LIST}`,
        method: 'POST',
        body: arg,
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: EApiTags.CANDIDATE as const, id })), EApiTags.CANDIDATE]
          : [EApiTags.CANDIDATE],
    }),
  }),
});

export const { useGetCandidatesListQuery } = candidatesListApi;
