// Table config
import { ColumnsType } from 'antd/es/table';

import {
  renderDateCell,
  renderInterviewScoreCell,
  renderNameCell,
  renderProgressCell,
  renderTechTaskScoreCell,
} from './cellRenderers';
import { TCandidatesListTableRecord } from './types';

export const columns: ColumnsType<TCandidatesListTableRecord> = [
  {
    title: 'Кандидат',
    key: 'candidateName',
    render: renderNameCell,
    sorter: true,
  },
  {
    title: 'Этап отбора',
    key: 'stage',
    render: renderProgressCell,
    sorter: true,
  },
  {
    title: 'Интервью',
    key: 'interviewScore',
    render: renderInterviewScoreCell,
    sorter: true,
  },
  {
    title: 'Тех. задание',
    key: 'techTaskScore',
    render: renderTechTaskScoreCell,
    sorter: true,
  },
  {
    title: 'Дата добавления',
    key: 'createdAt',
    render: renderDateCell,
    sorter: true,
  },
];