import React from 'react';

import { Button } from 'components/ui/Button';
import { ReactComponent as IconDuplicate } from 'assets/images/svg/duplicate.svg';


export const ButtonDuplicate = () => (
  <Button
    onlyIcon="R"
    style={{
      width: '24px',
      height: '24px',
      backgroundColor: 'transparent',
      outline: '1px solid #E6E8EF',
    }}
    title="Копировать"
  >
    <IconDuplicate />
  </Button>
);
