import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

import { routes } from 'routing/routes';

export const NotFoundPage = () => {
  const title = 'Упс... Страница не найдена';
  const subTitle = 'Извините, страницы с таким адресом не существует.';

  const HomeButton = (
    <Link to={routes.root}>
      <Button type="primary">
        Домой
      </Button>
    </Link>
  );

  return <Result title={title} subTitle={subTitle} extra={HomeButton} />;
};
