import { EQuestionCategories } from 'constants/QuestionCategories';

export const categoryTitle = {
  [EQuestionCategories.CASES]: 'Кейсы',
  [EQuestionCategories.HARD]: 'Технические вопросы',
  [EQuestionCategories.SOFT]: 'Soft Skills',
  all: 'Все',
};

type TTagColor = 'tag-blue' | 'tag-red' | 'tag-orange' | 'tag-green';

export const tagColor = (n: number): TTagColor => {
  if (n > 5) return 'tag-blue';
  if (n < 3) return 'tag-red';
  if (n === 3) return 'tag-orange';
  return 'tag-green';
};
