export enum apiRoutes {
  LOGIN = '/login',
  CANDIDATE = '/candidate',
  CANDIDATES_LIST = '/candidates',
  DIRECTION = '/direction',
  VACANCY = '/vacancy',
  COMMENTS_ABOUT_CANDIDATE = '/comment',
  SELECTING_STATISTIC = '/selectingStatistic',
  RESULTS_INTERVIEW = '/result',
  INTERVIEW_BLOCKS = "/block",
  QUESTIONS = "/question",
  CUSTOM_QUESTIONS = "/question/custom",
  USER = '/current_user',
  TECH_TASK_SOLUTION = '/tech_solution',
  TECH_TASK_SOLUTION_CANDIDATE='/tech_solution_candidate',
  STAMP = '/tech_task_stamp',
  LAST_STAMP = '/tech_task_stamp_last',
  TECH_TASK = '/techTask',
}
