import React from 'react';
import { Typography } from 'antd';

import { Tooltip } from 'components/ui/Tooltip';

import { ellipsisCutStingTo } from 'utils/stringUtils';

import { ButtonDuplicate } from './ButtonDuplicate';
import { hint } from 'components/ui/Hint';

import cx from './index.module.scss';

const { Text } = Typography;

export const ContactEmail = ({ contactString }: { contactString: string }) => {
  const limit = 25;
  const { length } = contactString;

  const copyableConfig = {
    icon: [<ButtonDuplicate />, <ButtonDuplicate />],
    tooltips: [false, false],
    text: contactString,
    onCopy: () => hint.info('E-mail скопирован в буфер'),
  };

  const isLimited = length > limit;

  const contactLimited = isLimited ? ellipsisCutStingTo(contactString, limit) : contactString;

  return (
    <Text copyable={copyableConfig} className={cx.contact}>
      {isLimited ? (
        <Tooltip title={contactString} placement="topRight">
          {contactLimited}
        </Tooltip>
      ) : (
        contactLimited
      )}
    </Text>
  );
};
