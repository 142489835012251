import { TRouterParams } from './types';

export const params: TRouterParams = {
  candidateId: ':candidateId',
  solutionId: ':solutionId',
};

export const routes = {
  root: '/',
  auth: '/auth',
  newCandidate: '/newCandidate',
  dashboard: '/dashboard',
  notFound: '*',
  candidatesList: `/candidates`,
  candidateProfile: (param) => `/candidates/${param}`,
  candidateEdit: (param) => `/candidates/${param}/edit`,
  interviewPage: (param) => `/candidates/${param}/interview`,
  testingPage: (param) => `testing/${param}`,
  interviewEdit: (param) => `/candidates/${param}/interviewEdit`,
  techTaskWatch: (param,solutionId) => `/candidates/${param}/${solutionId}`,
};
