import { Rule } from 'antd/lib/form';

import { rules } from 'constants/inputRules';

type TRules = {
  surname: Rule[];
  name: Rule[];
  middlename: Rule[];
  phone: Rule[];
  email: Rule[];
  job: Rule[];
  direction: Rule[];
  level: Rule[];
  stage?: Rule[];
  whitespace?: Rule[];
};

export const CandidateFormRules: TRules = {
  surname: [rules.required, rules.min(2), rules.max(50), rules.patternCyrillic, rules.whitespace],
  name: [rules.required, rules.min(2), rules.max(50), rules.patternCyrillic, rules.whitespace],
  middlename: [rules.min(2), rules.max(50), rules.patternCyrillic, rules.whitespace],
  phone: [rules.patternPhone],
  email: [rules.email, rules.max(320), rules.emailNoCyrilic, rules.emailValidation],
  job: [rules.required],
  direction: [rules.required],
  level: [rules.required],
};
