import React from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContainer } from 'components/AuthContainer';

import { useAppSelector } from 'store/hooks';
import { getIsAuth } from 'store/auth/selectors';
import { routes } from 'routing/routes';

export const AuthPage = () => {
  const isAuth = useAppSelector(getIsAuth);

  if (isAuth) {
    return <Navigate to={routes.root} />;
  }
  return <AuthContainer />;
};
