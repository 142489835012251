import React from 'react';
import { Switch, SwitchProps } from 'antd';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CandidatesListActions } from 'store/candidatesList/slice';
import { getCandidatesListShowOnlySelected } from 'store/candidatesList/selectors';

type TOnlyChosenFilterProps = SwitchProps & {
  wrapClassName: string;
  labelClassName: string;
}

export const OnlyChosenFilter = ({ wrapClassName, labelClassName }: TOnlyChosenFilterProps) => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(getCandidatesListShowOnlySelected);

  const onSwitch = (checked) => {
    dispatch(CandidatesListActions.setShowOnlySelected(checked));
  };

  return (
    <div className={wrapClassName}>
        <span className={labelClassName}>
          Только выбранные
        </span>
      <Switch checked={checked}
              onChange={onSwitch} />
    </div>
  );
};