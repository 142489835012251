import React, { useMemo } from 'react';
import moment from 'moment';
import { DatePicker, DatePickerProps } from 'antd';
import { useSelector } from 'react-redux';

import { ReactComponent as CalendarSvg } from 'assets/images/svg/Calendar.svg';

import { useAppDispatch } from 'store/hooks';
import { getCandidatesListFilterEndDate, getCandidatesListFilterStartDate } from 'store/candidatesList/selectors';
import { CandidatesListActions } from 'store/candidatesList/slice';
import { RangePickerProps } from 'antd/es/date-picker';

const { RangePicker } = DatePicker;

export const DateFilter = ({ className, dropdownClassName }: DatePickerProps) => {
  const dispatch = useAppDispatch();
  const startDate = useSelector(getCandidatesListFilterStartDate);
  const endDate = useSelector(getCandidatesListFilterEndDate);
  const value = useMemo<RangePickerProps['value']>(() => ([
    startDate ? moment(startDate) : null,
    endDate ? moment(endDate) : null,
  ]), [startDate, endDate]);

  const onChange = (newRange: RangePickerProps['value']) => {
    if (newRange) {
      if (newRange[0] && newRange[0].year())
        dispatch(CandidatesListActions.setStartDate(newRange[0].format('YYYY-MM-DD')));
      if (newRange[1] && newRange[1].year())
        dispatch(CandidatesListActions.setEndDate(newRange[1].format('YYYY-MM-DD')));
    }
  };

  return <RangePicker className={className}
                      dropdownClassName={dropdownClassName}
                      format='DD-MM-YYYY'
                      size='large'
                      allowClear={false}
                      allowEmpty={[true, true]}
                      suffixIcon={<CalendarSvg />}
                      value={value}
                      onChange={onChange}
  />;
};