import React, { ReactNode } from 'react';
import { Tooltip as TooltipAntd, TooltipProps } from 'antd';

import cx from './index.module.scss';

type TProps = {
  title: string;
  children: ReactNode;
};

export const Tooltip = (props: TProps & TooltipProps) => {
  const { title, children, ...restProps } = props;

  const tooltipProps = {
    title,
    color: '#F1F2F4',
    trigger: 'hover',
    overlayClassName: cx.tooltip,
  };

  return (
    <TooltipAntd {...tooltipProps} {...restProps}>
      {children}
    </TooltipAntd>
  );
};
