import React, { FC } from 'react';
import { Tag } from 'antd';

import cn from 'classnames/bind';
import { tagColor } from './adapters';
import { wordScore } from 'utils/wordScore';

import styles from './index.module.scss';

const cx = cn.bind(styles);

type TScoreTagProps = {
  count: number;
  percent?: true;
};

const ScoreTag: FC<TScoreTagProps> = ({ count, percent }) => (
  <Tag className={cx(styles.tag, tagColor(percent ? Infinity : count))}>
    {percent ? `${count} %` : wordScore(count)}
  </Tag>
);

export default ScoreTag;
