export const INTERVIEW_SLICE = 'interview' as const;

export type TQuestionMark = {
    name: string;
    value: number;
}

export type TInterviewBlock = {
    id: number,
    title: string
}

export type TQuestion = {
    id: number,
    text: string,
    blockId: number,
}

export type TInterviewQuestion = {
    score?: number | null,
    question: string,
    comment: string,
    id: string,
    custom: boolean,
}

export type TInterviewEditQuestion = {
    score?: number,
    question: string,
    comment: string,
    id: string,
}

export type TInterviewFormValues = { [blockId: string]: TInterviewQuestion[] }
export type TInterviewFormEditValues = { [blockId: string]: TInterviewEditQuestion[] }

export type TInterview = {
    score: { [key: string]: number | null },
    isPartialSubmitCancelled: boolean,
    questions: { [blockId: string]: { [id: string]: { text: string, chosen: boolean } } },
    initialInterviewValues: null | TInterviewFormValues,
}

export type TPostInterviewRequest = {
    score?: number,
    comment: string,
    question: number
}[];

export type TCreateCustomQuestionsRequest = {
    candidate_id: number,
    block: number,
    text: string
}[];

export type TCreateCustomQuestionsResponse = {
    text: string,
    blockId: number,
    candidate_id: number,
    id: number
}[];

export type TEditInterviewResultRequest = {
    score?: number,
    comment: string,
    question: number
}[];

export type TGetInterviewQuestion = {
    id: number;
    text: string;
    results: {
        id: string;
        score: number;
        comment: string
    }[];
    // ахахаах. Бэк не может прислать объект. Он присылает массив с объектом
};

export type TGetInterviewResultResponse = {
    id: number;
    title: string;
    questions: TGetInterviewQuestion[];
}[];

export type TGetInterviewResultTransformed = {
    initialValues: TInterviewFormEditValues | null,
    blocks: TInterviewBlock[] | null;
}
