import React from 'react';
import cn from 'classnames';

import { ReactComponent as LogoSvg } from 'assets/images/svg/logo_hardskills.svg';

import cx from './index.module.scss';

type TLogoProps = {
  className?: string;
  type: 'white' | 'blue' | 'black';
};

export const Logo = ({ className, type }: TLogoProps) => (
  <LogoSvg className={cn(className, cx[type])} />
);
