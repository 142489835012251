import React from 'react';
import { Typography } from 'antd';

import { Button } from 'components/ui/Button';
import { InstructionText } from './InstructionText';

import cx from './index.module.scss';

const { Text } = Typography;

type TProps = { read: () => void };

export const Instruction = (props: TProps) => {
  const { read } = props;

  return (
    <div className={cx.wrapper}>
      <div className={cx.insctruction_container}>
        <header className={cx.header}>
          <Text className={cx.title}>Тестовое задание</Text>
        </header>
        <main className={cx.main}>
          <InstructionText />
        </main>
        <footer className={cx.footer}>
          <Button type="primary" onClick={read} fixed>
            Начaть
          </Button>
        </footer>
      </div>
    </div>
  );
};
