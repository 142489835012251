import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const getCandidatesListSliceStore = (state: RootState) => state.candidatesList;

// Filters
export const getCandidatesListFilters = createSelector(getCandidatesListSliceStore,
  ({ filters }) => filters);
export const getCandidatesListFilterByName = createSelector(getCandidatesListFilters,
  ({ filterByNameValue }) => filterByNameValue);
export const getCandidatesListFilterStartDate = createSelector(getCandidatesListFilters,
  ({ startDate }) => startDate);
export const getCandidatesListFilterEndDate = createSelector(getCandidatesListFilters,
  ({ endDate }) => endDate);
export const getCandidatesListFilterByVacancy = createSelector(getCandidatesListFilters,
  ({ filterByVacancy }) => filterByVacancy);
export const getCandidatesListFilterByLevel = createSelector(getCandidatesListFilters,
  ({ filterByLevel }) => filterByLevel);
export const getCandidatesListFilterByDirection = createSelector(getCandidatesListFilters,
  ({ filterByDirection }) => filterByDirection);
export const getCandidatesListFilterByStage = createSelector(getCandidatesListFilters,
  ({ filterByStage }) => filterByStage);
export const getCandidatesListFilterByStatus = createSelector(getCandidatesListFilters,
  ({ filterByStatus }) => filterByStatus);

// Sort
export const getCandidatesListSort = createSelector(getCandidatesListSliceStore,
  ({ sort }) => sort);
export const getCandidatesListSortOrder = createSelector(getCandidatesListSort,
  ({ order }) => order);
export const getCandidatesListSortField = createSelector(getCandidatesListSort,
  ({ orderColumnName }) => orderColumnName);

// Pagination
export const getCandidatesListPagination = createSelector(getCandidatesListSliceStore,
  ({ pagination }) => pagination);
export const getCandidatesListPageSize = createSelector(getCandidatesListPagination,
  ({ take }) => take);

// Selection
export const getCandidatesListSelection = createSelector(getCandidatesListSliceStore,
  ({ selection }) => selection);
export const getCandidatesListSelectionList = createSelector(getCandidatesListSelection,
  ({ list }) => list);
export const getCandidatesListShowOnlySelected = createSelector(getCandidatesListSelection,
  ({ showOnlySelected }) => showOnlySelected);

// Misc
export const getCandidatesListActiveFiltersCount = createSelector(getCandidatesListFilters, getCandidatesListShowOnlySelected,
  (filters, showOnlySelected) => Object.values({ ...filters, showOnlySelected })
    .reduce((acc, cur) => cur ? acc + 1 : acc, 0));