import React, { FC } from 'react';

import { Slider } from 'antd';
import { Controls } from './Controls';

import { formatMsTo } from 'utils/dateUtils';

import style from './index.module.scss';

type TCodePlayer = {
  max: number;
  currentTime: number;
  totalTime: number;
  currentMark: number;
  handleChange: (value: number) => void;
  handleRewind: (type: string) => void;
  handlePlay: () => void;
  isPause: boolean;
};

export const CodePlayer: FC<TCodePlayer> = ({
  max,
  currentTime,
  totalTime,
  currentMark,
  handleChange,
  handleRewind,
  handlePlay,
  isPause,
}) => {
  const currentMarkTime = formatMsTo(currentTime, 'HH:mm:ss');
  const timeAtEnd = formatMsTo(totalTime, 'HH:mm:ss');

  return (
    <div className={style.player_input}>
      <Controls handleRewind={handleRewind} handlePlay={handlePlay} isPause={isPause} />
      <div className={style.player_time_mark}>{currentMarkTime} </div>
      <Slider
        className={style.player_input_slider}
        tooltipVisible={false}
        min={0}
        defaultValue={currentMark}
        value={currentMark}
        max={max}
        onChange={(value) => handleChange(value)}
      />
      <div className={style.player_time_mark}>{timeAtEnd}</div>
    </div>
  );
};
