import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import { useParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';
import { Question } from '../Question';
import { QuestionsSearch } from './QuestionsSearch';

import { TRouterParams } from 'routing/types';
import { useAppDispatch } from 'store/hooks';
import { setQuestionIsChosen } from 'store/interview/slice';
import { TInterviewBlock, TInterviewFormValues, TInterviewQuestion } from 'store/interview/types';

import cx from './index.module.scss';

type TProps = TInterviewBlock & { number: number };

const emptyQuestion: TInterviewQuestion = {
  comment: '',
  question: '',
  id: '',
  custom: false,
};

export const Block = ({ title, id, number }: TProps) => {
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const dispatch = useAppDispatch();
  const form = Form.useFormInstance<TInterviewFormValues>();
  const [questionsIds, setQuestionIds] = useState<string[]>(
    form.getFieldValue(id).map((question: TInterviewQuestion) => `${uid}_${question.id}`),
  );
  const initialQuestionsCount = useRef(questionsIds.length);
  const [customQuestionsCount, setCustomQuestionsCount] = useState<number>(
    form.getFieldValue(id).filter((question: TInterviewQuestion) => question.custom).length,
  );

  const blocksContainerRef = useRef<HTMLDivElement | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (
      questionsIds.length > initialQuestionsCount?.current &&
      blocksContainerRef.current &&
      blocksContainerRef.current.lastElementChild
    ) {
      blocksContainerRef.current.lastElementChild.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
    localStorage.setItem(`${uid}_block_${id}`, JSON.stringify(questionsIds));
  }, [questionsIds]);

  return (
    <div className={cx.root}>
      <div className={cx.line} />
      <span className={cx.block_name}>{`Блок ${number}. ${title}`}</span>
      <Form.List name={id}>
        {(fields, { add }) => (
          <>
            <div ref={blocksContainerRef}>
              {fields.map((field) => (
                <Question
                  blockId={`${id}`}
                  serialNumber={field.name}
                  key={field.key}
                  technical={number === 1}
                />
              ))}
            </div>
            <div className={cx.buttons_container}>
              <Form.Item>
                <Button
                  onClick={() => {
                    showModal();
                  }}
                  type="default"
                >
                  Добавить из списка
                  <PlusOutlined />
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => {
                    const newCustomId = `${customQuestionsCount}_${id}_custom`;
                    const newQuestion: TInterviewQuestion = {
                      ...emptyQuestion,
                      custom: true,
                      id: newCustomId,
                    };
                    if (number === 1) {
                      newQuestion.score = null;
                    }
                    add(newQuestion);
                    setQuestionIds((value) => [...value, `${uid}_${newCustomId}`]);
                    setCustomQuestionsCount((value) => value + 1);
                  }}
                >
                  Ввести свой вопрос
                  <PlusOutlined />
                </Button>
              </Form.Item>
            </div>
            <Modal
              title={null}
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              className={cx.search_modal}
              closable={false}
              bodyStyle={{ padding: '0px' }}
              destroyOnClose
            >
              <QuestionsSearch
                blockId={String(id)}
                handleChooseQuestion={(questionId: string, question: string) => {
                  setIsModalOpen(false);
                  dispatch(setQuestionIsChosen({ blockId: String(id), questionId }));
                  const newQuestion: TInterviewQuestion = {
                    ...emptyQuestion,
                    question,
                    id: questionId,
                  };
                  if (number === 1) {
                    newQuestion.score = null;
                  }
                  add(newQuestion);
                  setQuestionIds((value) => [...value, `${uid}_${questionId}`]);
                }}
              />
            </Modal>
          </>
        )}
      </Form.List>
    </div>
  );
};
