import React, { useEffect } from 'react';
import { Select } from 'antd';

import { EStatus } from 'constants/stageProgress';
import { useEditCandidateByIdMutation } from 'store/candidate';

import { hint } from 'components/ui/Hint';

import cx from './index.module.scss';

const { Option } = Select;

const statuses = Object.values(EStatus);

type TProps = {
  status: EStatus;
  setStatus: React.Dispatch<React.SetStateAction<EStatus>>;
  candidateId: string;
};

export const SelectStatus = (props: TProps) => {
  const { status, setStatus, candidateId } = props;

  const [editCandidiate, { isSuccess, isError }] = useEditCandidateByIdMutation();

  useEffect(() => {
    if (isSuccess) {
      hint.success('Статус успешно изменён');
    }
    if (isError) {
      hint.error('Ошибка изменения статуса');
    }
  }, [isSuccess, isError]);

  const handleChange = (value: EStatus) => {
    setStatus(value);
    editCandidiate({ id: candidateId, status: value });
  };

  return (
    <Select value={status} onChange={handleChange} className={cx.select}>
      {statuses.map((status) => (
        <Option value={status} key={status}>
          {status}
        </Option>
      ))}
    </Select>
  );
};
