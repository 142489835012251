import React, { FC } from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';
import cn from 'classnames/bind';

import styles from './index.module.scss';

interface ExtendedButtonProps {
  fixed?: boolean;
  onlyIcon?: 'L' | 'R';
}

type TButton = ButtonProps & ExtendedButtonProps;

export const Button: FC<TButton> = ({ fixed, onlyIcon, ...rest }: TButton) => {
  const cx = cn.bind(styles);

  return (
    <div className={styles.button}>
      <AntdButton
        className={cx(
          { fixed_width: fixed },
          { only_icon_l: onlyIcon === 'L' },
          { only_icon_r: onlyIcon === 'R' },
        )}
        {...rest}
      />
    </div>
  );
};
