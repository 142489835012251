import React from 'react';
import { Modal, ModalProps } from 'antd';

import { ReactComponent as IconWarning } from 'assets/images/svg/warning.svg';
import { ReactComponent as IconSuccess } from 'assets/images/svg/verify-circle.svg';
import { ReactComponent as IconError } from 'assets/images/svg/circle-error.svg';
import { ReactComponent as IconInfo } from 'assets/images/svg/info.svg';
import { Button } from 'components/ui/Button';

import cx from './index.module.scss';

type TModalType = 'success' | 'attention' | 'error' | 'info' | 'warning';

interface IExtendedModalProps {
  okText: string;
  cancelText: string;
  contentText: string;
  headerText?: string;
  handleOk: (e: React.MouseEvent) => void;
  handleCancel: (e: React.MouseEvent) => void;
  type: TModalType;
}

export type TModalWarningProps = IExtendedModalProps & ModalProps;

const icons: { [type in TModalType]: React.FC } = {
  success: IconSuccess,
  attention: IconWarning,
  error: IconError,
  info: IconInfo,
  warning: IconWarning,
};

const defaultHeaders: { [type in TModalType]: string } = {
  success: 'Успешно!',
  attention: 'Внимание!',
  error: 'Ошибка',
  info: 'Информация',
  warning: 'Внимание!',
};

export const ModalWarning = (props: TModalWarningProps) => {
  const {
    headerText,
    okText,
    cancelText,
    contentText,
    handleOk,
    handleCancel,
    type,
    ...restProps
  } = props;

  const headerContent = headerText ?? defaultHeaders[type];

  return (
    <Modal closable={false} centered footer={false} bodyStyle={{ padding: 0 }} {...restProps}>
      <section className={cx.wrapper}>
        <header className={cx.icon}>{React.createElement(icons[type])}</header>
        <main className={cx.main}>
          <h4 className={cx.title}>{headerContent}</h4>
          <div className={cx.content}>{contentText}</div>
        </main>
        <footer className={cx.footer}>
          <Button type="primary" fixed danger={type === 'warning'} onClick={handleOk}>
            {okText}
          </Button>
          <Button type="default" onClick={handleCancel} fixed>
            {cancelText}
          </Button>
        </footer>
      </section>
    </Modal>
  );
};
