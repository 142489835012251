import React from 'react';
import { Avatar, List, Typography } from 'antd';

import { TCommentAboutCandidate } from 'store/commentsAboutCandidate/types';
import { formatDateBeforeEightDays } from 'utils/dateUtils';

import cx from './index.module.scss';

const { Item } = List;
const { Text, Paragraph } = Typography;
const photoPlaceholder =
  'https://tlgrm.ru/_/stickers/e7e/bd2/e7ebd20f-63fe-3d68-ae03-d1b43c16a271/10.webp';

export const ListItem = ({ item }: { item: TCommentAboutCandidate }) => {
  const { user, text, createdDate } = item;
  return (
    <Item key={item.id}>
      <Item.Meta
        avatar={<Avatar src={user.photo || photoPlaceholder} size={42} />}
        title={<span>{`${user.lastName} ${user.firstName}`}</span>}
        description={user.role}
      />
      <div className={cx.list_item_content}>
        <Paragraph className={cx.item_content_text}>{text}</Paragraph>
        <Text className={cx.item_content_date}>{formatDateBeforeEightDays(createdDate)}</Text>
      </div>
    </Item>
  );
};
