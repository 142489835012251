import React, { useEffect, useState, useMemo } from 'react';
import { Empty, Input } from 'antd';

import { QuestionsListElement } from './QuestionsListElement';

import { useAppSelector } from 'store/hooks';
import { getQuestionsByBlockId } from 'store/interview/selectors';
import { ReactComponent as IconSearch } from 'assets/images/svg/SearchLarge.svg';

import cx from './index.module.scss';

type TProps = {
  blockId: string;
  handleChooseQuestion: (questionId: string, question: string) => void;
};

export const QuestionsSearch = ({ blockId, handleChooseQuestion }: TProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [listFocus, setListFocus] = useState<number>(0);
  const questions = useAppSelector(getQuestionsByBlockId(blockId));

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const searchedQuestions = useMemo(() => {
    if (!searchValue) {
      return Object.entries(questions).reverse();
    }
    return Object.entries(questions)
      .filter((item) => item[1].text.toLowerCase().includes(searchValue.toLocaleLowerCase()))
      .reverse();
  }, [searchValue, questions]);

  useEffect(() => {
    const handleUpAndDownKeys = (e: KeyboardEvent) => {
      if (e.code === 'ArrowDown') {
        e.preventDefault();
        setListFocus((value) => {
          if (value === searchedQuestions.length - 1) {
            return value;
          }
          return value + 1;
        });
      }
      if (e.code === 'ArrowUp') {
        e.preventDefault();
        setListFocus((value) => {
          if (value === 0) {
            return value;
          }
          return value - 1;
        });
      }
    };
    if (searchedQuestions.length) {
      setListFocus(0);
      document.body.addEventListener('keydown', handleUpAndDownKeys);
    }

    return () => {
      if (searchedQuestions.length) {
        document.body.removeEventListener('keydown', handleUpAndDownKeys);
      }
    };
  }, [searchedQuestions.length]);

  return (
    <>
      <Input
        size="large"
        placeholder="Поиск вопроса"
        value={searchValue}
        onChange={handleChange}
        className={cx.input}
        prefix={<IconSearch />}
      />
      <div className={cx.questions_list}>
        {searchedQuestions.length ? (
          searchedQuestions.map((item, i) => (
            <QuestionsListElement
              focused={i === listFocus}
              id={item[0]}
              text={item[1].text}
              chosen={item[1].chosen}
              handleClick={handleChooseQuestion}
              key={item[0]}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ничего не найдено" />
        )}
      </div>
    </>
  );
};
