import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clientCookies } from 'utils/cookies';
import { authApi } from '.';
import { AUTH_SLICE, TAuth } from './types';

const initialState: TAuth = {
  loginError: null,
  isAuth: Boolean(clientCookies.getToken())
};

export const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState,
  reducers: {
    setLoginValidationError: (state, action: PayloadAction<string>) => {
      state.loginError = { message: action.payload, type: 'validation' };
    },
    logout: (state) => {
      state.isAuth = false;
      clientCookies.deleteToken();
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action) => {
        clientCookies.setToken(action.payload.token)
        state.isAuth = true;
      },
    )
    builder.addMatcher(
      authApi.endpoints.login.matchRejected,
      (state, { payload }) => {
        if (payload && (payload.status === 401 || payload.status === 400)) {
          state.loginError = { message: 'Неверные логин или пароль', type: 'invalid data' };
        }
        else {
          state.loginError = { message: 'Произошла ошибка на сервере', type: 'server' }
        }
      }
    )
  },
});

export const { setLoginValidationError, logout } = authSlice.actions;
export default authSlice.reducer;



