import { api } from 'network/api/api';
import { EApiTags } from 'network/api/types';
import { apiRoutes } from 'network/apiRoutes';
import { TVacancy } from './types';

export const vacancyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVacancies: builder.query<TVacancy[], void>({
      query: () => ({
        url: `${apiRoutes.VACANCY}`,
        method: 'GET',
      }),

      providesTags: [EApiTags.VACANCY],
    }),
    addVacancy: builder.mutation<TVacancy, Partial<TVacancy>>({
      query: (value) => ({
        url: `${apiRoutes.VACANCY}`,
        method: 'POST',
        body:value,
      }),
      invalidatesTags: [EApiTags.VACANCY],
    }),
    getVacancyById: builder.query<TVacancy, string>({
      query: (id) => ({
        url: `${apiRoutes.VACANCY}/${id}`,
        method: 'GET',
      }),
      providesTags: [EApiTags.VACANCY],
    }),
    editVacancyById: builder.mutation<TVacancy, Partial<TVacancy>>({
      query: ({ id, ...body }) => ({
        url: `${apiRoutes.VACANCY}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EApiTags.VACANCY],
    }),
    deleteVacancyById: builder.mutation<Partial<TVacancy>, string>({
      query: (body) => ({
        url: `${apiRoutes.VACANCY}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [EApiTags.VACANCY],
    }),
  }),
});

export const { useGetAllVacanciesQuery,useAddVacancyMutation } = vacancyApi;
