import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Empty, Form } from 'antd';
import { Link, Navigate, useParams } from 'react-router-dom';
import FormItem from 'antd/lib/form/FormItem';

import Title from 'antd/lib/typography/Title';
import { Button } from 'components/ui/Button';
import { BlockEdit } from 'components/InterviewElements/Block/BlockEdit';
import { Loader } from 'components/ui/Loader';

import { hint } from 'components/ui/Hint';
import { routes } from 'routing/routes';
import { TRouterParams } from 'routing/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetInterviewBlocksQuery, useGetInterviewResultQuery } from 'store/interview';
import { getOverallMark } from 'store/interview/selectors';
import { clearInterview } from 'store/interview/slice';
import {
  TGetInterviewResultTransformed,
  TInterviewBlock,
  TInterviewEditQuestion,
  TInterviewFormEditValues,
} from 'store/interview/types';
import { compareQuestions } from './compareQuestions';

import common from '../index.module.scss';
import cx from './index.module.scss';

type TProps = {
  onSave: (values: TInterviewEditQuestion[]) => void;
  onCancelSave: (values: TInterviewEditQuestion[]) => void;
  isLoading: boolean;
};

export const InterviewFormEdit = ({ onSave, isLoading, onCancelSave }: TProps) => {
  const [form] = Form.useForm<TInterviewFormEditValues>();
  const overallMark = useAppSelector(getOverallMark);
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const { isFetching, isSuccess, isError, data } = useGetInterviewResultQuery(uid);
  const {
    isSuccess: isGetBlocksSuccess,
    isError: isGetBlocksError,
    data: allBlocks,
  } = useGetInterviewBlocksQuery();
  const [pathsOfChangedValues, setPathsOfChangedValues] = useState<string[]>([]);
  const [emptyCommentError, setEmptyCommentError] = useState(false);
  const dispatch = useAppDispatch();

  const { blocks, initialValues } = useMemo<TGetInterviewResultTransformed>(() => {
    if (isSuccess && data && isGetBlocksSuccess) {
      const firstBlockId = allBlocks[0].id;
      const blocks: TInterviewBlock[] = [];
      const initialValues: TInterviewFormEditValues = {};
      for (let i = 0; i < data.length; i += 1) {
        const currentBlockId = data[i].id;
        const techBlock = currentBlockId === firstBlockId;
        const block: TInterviewBlock = { id: currentBlockId, title: data[i].title };
        blocks.push(block);
        initialValues[block.id] = data[i].questions.map((q) => {
          const changedQuestion: TInterviewEditQuestion = {
            question: q.text,
            comment: q.results[0].comment,
            id: String(q.id),
          };
          if (techBlock) {
            changedQuestion.score = q.results[0].score;
          }
          return changedQuestion;
        });
      }
      return { blocks, initialValues };
    }
    return { blocks: null, initialValues: null };
  }, [isSuccess, data, isGetBlocksSuccess]);

  const prepareChangedValues = () => {
    const changedValues: TInterviewEditQuestion[] = [];
    for (let i = 0; i < pathsOfChangedValues.length; i += 1) {
      changedValues.push(form.getFieldValue(JSON.parse(pathsOfChangedValues[i])));
    }
    return changedValues;
  };

  useEffect(
    () => () => {
      dispatch(clearInterview());
    },
    [],
  );

  useEffect(() => {
    if (isError || isGetBlocksError) {
      hint.error('Ошибка получения данных сервера');
    }
  }, [isError, isGetBlocksError]);

  const handleSubmit = () => {
    onSave(prepareChangedValues());
  };

  const handleCancel = () => {
    if (pathsOfChangedValues.length) {
      onCancelSave(prepareChangedValues());
    }
  };

  const onChange = (e) => {
    if (initialValues) {
      let emptyComment = false;
      const blockIdArray = Object.keys(e);

      for (let i = 0; i < blockIdArray.length; i += 1) {
        const questionIdArray = Object.keys(e[blockIdArray[i]]);
        for (let j = 0; j < questionIdArray.length; j += 1) {
          const path = JSON.stringify([blockIdArray[i], questionIdArray[j]]);
          const questionData = form.getFieldValue([blockIdArray[i], questionIdArray[j]]);
          if (pathsOfChangedValues.includes(path)) {
            if (
              compareQuestions(questionData, initialValues[blockIdArray[i]][questionIdArray[j]])
            ) {
              setPathsOfChangedValues((value) => {
                value.splice(value.indexOf(path), 1);
                return [...value];
              });
            }
          } else {
            setPathsOfChangedValues((value) => [...value, path]);
          }
          if (allBlocks && blockIdArray[i] !== String(allBlocks[0].id) && !questionData.comment) {
            emptyComment = true;
          }
        }
      }
      setEmptyCommentError(emptyComment);
    }
  };

  if (isError || isGetBlocksError) {
    <Navigate to={routes.candidateProfile(uid)} />;
  }
  if (
    isSuccess &&
    isGetBlocksSuccess &&
    !isFetching &&
    (!blocks || !initialValues || blocks.length === 0)
  ) {
    return (
      <div className={common.wrapper}>
        <div className={cn(common.container, cx.empty_block)}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ничего не найдено" />;
          <Link to={routes.candidateProfile(uid)}>
            <Button type="primary" htmlType="button">
              В карточку кандидата
            </Button>
          </Link>
        </div>
      </div>
    );
  }
  if (isSuccess && !isFetching && blocks && initialValues && isGetBlocksSuccess) {
    return (
      <div className={common.wrapper}>
        <div className={common.container}>
          <div className={common.header}>
            <Title level={3}>Режим редактирования</Title>
            <div className={common.points_container}>
              <span className={common.points_heading}>Итого:</span>
              <div className={common.points}>
                {overallMark === null ? '-' : <span>{overallMark}%</span>}
              </div>
            </div>
            <div className={common.line} />
          </div>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className={common.form}
            initialValues={initialValues}
            onValuesChange={onChange}
          >
            <div className={common.blocks_container}>
              {blocks.map((block, i) => (
                <BlockEdit
                  title={block.title}
                  id={block.id}
                  number={i + 1}
                  key={block.id}
                  technical={block.id === allBlocks[0].id}
                />
              ))}
            </div>
            <div className={common.button_container}>
              <div className={cn(common.line, common.lineTop)} />
              <div className={cx.edit_buttons_container}>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={!pathsOfChangedValues.length || emptyCommentError}
                  >
                    Сохранить изменения
                  </Button>
                </FormItem>
                {!pathsOfChangedValues.length || emptyCommentError ? (
                  <Link to={routes.candidateProfile(uid)}>
                    <Button type="default" htmlType="button">
                      Не сохранять
                    </Button>
                  </Link>
                ) : (
                  <Button type="default" htmlType="button" onClick={handleCancel}>
                    Не сохранять
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
  return <Loader />;
};
