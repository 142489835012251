import React, { FC } from 'react';

import { Mark } from './Mark';

import { TTechTaskStamp } from 'store/techTask/types';

import style from './index.module.scss';

type TTimeLine = {
  marks: TTechTaskStamp[];
  currentMark: number;
  onClick: (e: React.MouseEvent<HTMLOptionElement>) => void;
};

export const Timeline: FC<TTimeLine> = ({ marks, onClick, currentMark }) => {
  const totalTime = marks[marks.length - 1].createdAt - marks[0].createdAt;

  return (
    <div className={style.wrapper}>
      <div className={style.timeline}>
        {marks.map((mark, i) => (
          <Mark
            mark={mark}
            key={mark.createdAt}
            totalTime={totalTime}
            onClick={onClick}
            isCurrent={i === currentMark}
          />
        ))}
      </div>
    </div>
  );
};
