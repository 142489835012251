import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

import cx from './index.module.scss';

type TProps = {
  value: string;
  handleChange: (value: string) => void;
  handlePaste?: React.ClipboardEventHandler<HTMLDivElement>;
  disabled?: boolean;
};

export const CodeEditor = (props: TProps) => {
  const { value, handleChange, handlePaste, disabled } = props;
  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split('\n')
      .map((line, i) => `<span class=${cx.editorLineNumber}>${i + 1}</span>${line}`)
      .join('\n');

  return (
    <Editor
      textareaId="codeEditor"
      value={value}
      onValueChange={handleChange}
      onPaste={handlePaste}
      highlight={(code) => hightlightWithLineNumbers(code, languages.js)}
      padding={10}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 16,
      }}
      className={cx.editor}
      disabled={disabled}
    />
  );
};
