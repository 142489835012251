import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Result } from 'antd';

type IAppErrorBoundaryProps = {
  children: React.ReactNode;
};

const AppErrorFallbackUI = () => {
  const errorTitle = 'Возникла непредвиденная ошибка';
  const errorSubTitle =
    'Если она повторяется после перезагрузки окна браузера, пожалуйста, свяжитесь со службой технической поддержки';
  const reloadButton = (
    <Button type="primary" onClick={() => window.location.reload()}>
      Обновить
    </Button>
  );

  return <Result status="error" title={errorTitle} subTitle={errorSubTitle} extra={reloadButton} />;
};

export const AppErrorBoundary = ({ children }: IAppErrorBoundaryProps) => (
  <ErrorBoundary FallbackComponent={AppErrorFallbackUI}>{children}</ErrorBoundary>
);
