import { TInterviewFormValues } from "store/interview/types";
import { ECustomValidationStatuses } from "./types";

export const customFormValidation = (fields: TInterviewFormValues) => {
    const blockIdsArray = Object.keys(fields).sort();
    let formHasEmptyRequiredField = false;
    let formHasFullField = false;

    for (let i = 0; i < blockIdsArray.length; i += 1) {
        const questions = fields[blockIdsArray[i]]
        for (let j = 0; j < questions.length; j += 1) {
            if (formHasEmptyRequiredField) {
                if (i === 0 && questions[j].score || questions[j].custom && questions[j].question || questions[j].comment) {
                    formHasFullField = true;
                    break;
                }
            }
            else if (formHasFullField) {
                if (i === 0 && !questions[j].score || i !== 0 && !questions[j].comment || questions[j].custom && !questions[j].question) {
                    formHasEmptyRequiredField = true;
                    break;
                }
            }
            else {
                if (i === 0 && !questions[j].score || i !== 0 && !questions[j].comment || questions[j].custom && !questions[j].question) {
                    formHasEmptyRequiredField = true;
                }
                if (i === 0 && questions[j].score || questions[j].custom && questions[j].question || questions[j].comment) {
                    formHasFullField = true;
                }
            }
        }
        if (formHasEmptyRequiredField && formHasFullField) {
            return ECustomValidationStatuses.PARTIAL_EMPTY;
        }
    }

    if (formHasEmptyRequiredField) {
        return ECustomValidationStatuses.FULL_EMPTY;
    }

    return ECustomValidationStatuses.FULL;
}

export { ECustomValidationStatuses };
