import React, { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { CodeEditor } from 'components/ui/CodeEditor';
import { Button } from 'components/ui/Button';
import { TaskResultInfo } from './TaskResultInfo';
import { Loader } from 'components/ui/Loader';

import { routes } from 'routing/routes';
import { useGetTaskStampsQuery } from 'store/techTask';
import { TTechTaskStamp } from 'store/techTask/types';

import style from './index.module.scss';


type TTaskResult = {
  uid: string;
  id: string | number;
  time: string;
};

const checkPaste = (stamps: TTechTaskStamp[] | undefined): number => {
  if (!stamps) return 0;
  let count = 0;
  for (let i = 0; i < stamps.length; i += 1) {
    if (stamps[i].eventName === 'paste') {
      count += 1;
    }
  }
  return count;
};

export const TaskResult: FC<TTaskResult> = ({ uid, id, time }) => {
  const [pasteCount, setPasteCount] = useState<number>(0);

  const { data: stamps, isLoading } = useGetTaskStampsQuery(id);

  useEffect(() => {
    if (stamps) {
      setPasteCount(checkPaste(stamps));
    }
  }, [stamps]);

  if (isLoading) return <Loader />;

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <h3>Кандидат завершил задание</h3>
        <Link to={routes.techTaskWatch(uid, id)}>
          <Button type="primary" fixed disabled={ stamps && stamps.length < 2}>
            Подробнее
          </Button>
        </Link>
      </div>
      <div className={style.editor_wrapper}>
        <CodeEditor handleChange={() => null} value={stamps?.at(-1)?.code || ''} disabled />
      </div>
      <TaskResultInfo {...{ pasteCount, time, uid }} />
    </div>
  );
};
