import React, { FC, useState } from 'react';

import { Collapse } from 'antd';
import { Button } from 'components/ui/Button';

import { useGetAllTasksQuery } from 'store/techTask';
import { TTechTask } from 'store/techTask/types';

import { Loader } from 'components/ui/Loader';

import style from './index.module.scss';

const { Panel } = Collapse;

type TTaskSelect = {
  onSend: (id: string) => void;
};

export const TaskSelect: FC<TTaskSelect> = ({ onSend }) => {
  const { data: tasks, isLoading } = useGetAllTasksQuery();
  const [currentTaskId, setCurrentTaskId] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTaskId(e.target.value);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <h3>Выберите задание</h3>
        <Button
          type="primary"
          onClick={() => onSend(currentTaskId)}
          fixed
          disabled={!currentTaskId}
        >
          Создать ссылку
        </Button>
      </div>
      {isLoading && <Loader />}
      {tasks && (
        <Collapse
          expandIconPosition="end"
          defaultActiveKey={['1']}
          ghost
          className={style.collapse}
        >
          {tasks.map((task: TTechTask) => (
            <React.Fragment key={task.id}>
              <Panel
                key={task.id}
                className={style.panel}
                header={
                  <div className={style.panel_header}>
                    <input type="radio" value={task.id} name="task" onChange={handleChange} />
                    {task.title}
                  </div>
                }
              >
                <div className={style.description}>{task.description}</div>
              </Panel>
            </React.Fragment>
          ))}
        </Collapse>
      )}
    </div>
  );
};
