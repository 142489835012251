import React from 'react';
import { Affix, Divider, Layout } from 'antd';
import { Link, Outlet } from 'react-router-dom';

import { Logo } from 'components/Logo';
import { MainMenu } from './MainMenu';
import { Settings } from './Settings';
import { Path } from './Path';
import { Notifications } from './Notifications';
import { ReactComponent as LogoPlaceholder } from 'assets/images/svg/rtl-text.svg';
import { UserAvatar } from './UserAvatar';

import { routes } from 'routing/routes';
import { useClearExpiredInterviews } from 'utils/hooks/useClearExpiredInterviews';
import { useGetUserQuery } from 'store/user';

import cx from './index.module.scss';

const { Header, Sider, Content } = Layout;

export const MainLayout = () => {
  useClearExpiredInterviews();
  useGetUserQuery();
  return (
    <Layout className={cx.layout}>
      <Sider className={cx.sider} width={290} collapsible collapsedWidth={64}>
        <Affix>
          <div>
            <div className={cx.logo_container}>
              <Link to={routes.root}>
                <Logo type="blue" />
              </Link>
              <LogoPlaceholder className={cx.logo_placeholder} />
            </div>
            <MainMenu />
          </div>
        </Affix>
      </Sider>
      <Layout className={cx.main}>
        <Header className={cx.header}>
          <Path />

          <Settings className={cx.settings} />
          <Notifications className={cx.notifications} />
          <Divider type="vertical" className={cx.divider} />
          <UserAvatar />
        </Header>
        <Content className={cx.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
