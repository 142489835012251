import React from 'react';

import { PersonalDataView } from './PersonalDataView';
import { Loader } from 'components/ui/Loader';

import { useGetCandidateByIdQuery } from 'store/candidate';

import cx from './index.module.scss';

type TProps = { uid: string };

export const PersonalData = ({ uid }: TProps) => {
  const { data, isError, isLoading, isSuccess } = useGetCandidateByIdQuery(uid);

  return (
    <div className={cx.container}>
      {isSuccess && data && <PersonalDataView candidate={data} />}
      {isError && <h2>Error</h2>}
      {isLoading && <Loader />}
    </div>
  );
};
