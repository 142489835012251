import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalWarning } from 'components/ui/Modal';

import { useDeleteCandidateByIdMutation } from 'store/candidate';
import { routes } from 'routing/routes';
import { hint } from 'components/ui/Hint';
import { api } from 'network/api/api';
import { EApiTags } from 'network/api/types';
import { useAppDispatch } from 'store/hooks';

import cx from './PopoverDelete.module.scss';

export const PopoverDelete = ({ uid }: { uid: string }) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteCandidate, { isSuccess, isError }] = useDeleteCandidateByIdMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      hint.success('Кандидат успешно удалён');
      navigate(routes.candidatesList);
      dispatch(api.util.invalidateTags([{ type: EApiTags.CANDIDATE, id: uid }]));
    }
    if (isError) {
      hint.error('Не удалось удалить кандидата');
    }
  }, [isSuccess, isError]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    deleteCandidate(uid);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button type="button" className={cx.button} onClick={showModal}>
        Удалить
      </button>
      <ModalWarning
        visible={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        contentText="Вы уверены, что хотите удалить карточку?"
        cancelText="Нет"
        okText="Да"
        type="warning"
      />
    </>
  );
};
