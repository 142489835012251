import { ELevel } from 'constants/level';
import { EStage, EStatus } from 'constants/stageProgress';

type TOrder = 'ASC' | 'DESC'

export enum ECandidatesListOrderColumnName {
  CANDIDATE_NAME = 'candidateName',
  CREATED_AT = 'createdAt',
  DIRECTION_ID = 'directionId',
  STATUS = 'status',
  STAGE = 'stage',
  INTERVIEW_SCORE = 'interviewScore',
  TECH_TASK_SCORE = 'techTaskScore'
}

export type TCandidatesListPaginationArgs = {
  take: number;
  skip: number;
}

export type TCandidatesListFilterArgs = Partial<{
  filterByDirection: number[];
  filterByStage: EStage[];
  filterByStatus: EStatus[];
  filterByVacancy: string[];
  filterByLevel: ELevel[];
  startDate: string;
  endDate: string;
  filterByNameValue: string;
}>

export type TCandidatesListSortArgs = Partial<{
  order: TOrder;
  orderColumnName: ECandidatesListOrderColumnName;
}>

export type TCandidatesListQueryArgs =
  TCandidatesListPaginationArgs &
  TCandidatesListFilterArgs &
  TCandidatesListSortArgs

type TDirection = {
  id: number;
  title: string;
}

type TVacancy = {
  id: string;
  name: string;
}

export type TCandidatesList = {
  data: Array<{
    id: number;
    firstName: string;
    middleName: string | null;
    lastName: string;
    email: string | null;
    phone: string | null;
    interviewScore: number;
    techTaskScore: number;
    level: string;
    stage: string;
    status: string;
    createdAt: string;
    direction: TDirection;
    vacancy: TVacancy;
  }>;
  total: number;
  count: number;
};

export type TCandidatesListSelection = {
  list: Array<TCandidatesList['data'][number]>,
  showOnlySelected: boolean,
}
