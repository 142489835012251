import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { api } from 'network/api/api';
import { EApiTags } from 'network/api/types';
import { apiRoutes } from 'network/apiRoutes';
import { TDataForTechTask, TNewTaskSolution, TTechTask, TTechTaskSolution, TTechTaskStamp } from './types';

export const techTaskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    postStamp: builder.mutation<unknown, TTechTaskStamp>({
      query: (body) => ({
        url: `${apiRoutes.STAMP}`,
        method: 'POST',
        body,
      }),
    }),
    postTaskSolution: builder.mutation<TTechTaskSolution, TNewTaskSolution>({
      query: (body) => ({
        url: `${apiRoutes.TECH_TASK_SOLUTION}`,
        method: 'POST',
        body,
        
      }),
      invalidatesTags: (_res, _err, { candidateId }) => [
        { id: candidateId, type: EApiTags.CANDIDATE }
      ],
    }),
    getDataForTechTask: builder.query<TDataForTechTask, TTechTaskSolution['id']>({
      async queryFn(id, _queryApi, _extraOptions, fectWithBQ) {
        const solutionReq = await fectWithBQ(`${apiRoutes.TECH_TASK_SOLUTION}/${id}`);
        if (solutionReq.error) return { error: solutionReq.error as FetchBaseQueryError };
        const solution = solutionReq.data as TTechTaskSolution;

        const taskReq = await fectWithBQ(`${apiRoutes.TECH_TASK}/${solution.techTaskId}`);
        if (taskReq.error) return { error: taskReq.error as FetchBaseQueryError };
        const task = taskReq.data as TTechTask;

        const lastStampReq = await fectWithBQ(`${apiRoutes.LAST_STAMP}/${solution.id}`);
        if (lastStampReq.error) return { error: lastStampReq.error as FetchBaseQueryError };
        const lastStamp = lastStampReq.data as TTechTaskStamp;

        return { data: { solution, task, lastStamp } };
      },
    }),
    updateSolution: builder.mutation<
      TTechTaskSolution,
      Pick<TTechTaskSolution, 'startedAt' | 'endedAt' | 'id'>
    >({
      query: (body) => ({
        url: `${apiRoutes.TECH_TASK_SOLUTION}`,
        method: 'PATCH',
        body,
      }),
    }),
    getAllTasks: builder.query<TTechTask[], void>({
      query: () => ({
        url: `${apiRoutes.TECH_TASK}`,
        method: 'GET',
      }),
    }),
 
    getSolutionByCandidateId: builder.query<TTechTaskSolution, string>({
      query: (id) => ({
        url: `${apiRoutes.TECH_TASK_SOLUTION_CANDIDATE}/${id}`,
        method: 'GET',
      }),
    }),
    getTaskLastStamp: builder.query<TTechTaskStamp, TTechTaskSolution['techTaskId']>({
      query: (id) => ({
        url: `${apiRoutes.LAST_STAMP}/${id}`,
        method: 'GET',
      }),
    }),
    getTaskStamps: builder.query<TTechTaskStamp[], TTechTaskSolution['techTaskId']>({
      query: (id) => ({
        url: `${apiRoutes.STAMP}/${id}`,
        method: 'GET',
      }),
      
    
    }),
    getTechSolution: builder.query<TTechTaskSolution, TTechTaskSolution['techTaskId']>({
      query: (id) => ({
        url: `${apiRoutes.TECH_TASK_SOLUTION}/${id}`,
        method: 'GET',
      }),
    }),
   
  }),
});

export const {
  useGetTechSolutionQuery,
  useGetDataForTechTaskQuery,
  usePostStampMutation,
  useUpdateSolutionMutation,
  useGetSolutionByCandidateIdQuery,
  useGetAllTasksQuery,
  useGetTaskLastStampQuery,
  useLazyGetSolutionByCandidateIdQuery,
  useLazyGetTaskLastStampQuery,
  useGetTaskStampsQuery,
  useLazyGetTaskStampsQuery,
  usePostTaskSolutionMutation
} = techTaskApi;
