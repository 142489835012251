import React, { FC, useEffect, useState } from 'react';

import { Input, Modal } from 'antd';

import { TaskResult } from './TaskResult';
import * as StagesTask from './StagesTask';
import { Button } from 'components/ui/Button';
import { Loader } from 'components/ui/Loader';

import { useGetSolutionByCandidateIdQuery, usePostTaskSolutionMutation } from 'store/techTask';
import { useGetCandidateByIdQuery } from 'store/candidate';
import { routes } from 'routing/routes';
import { formatMsTo } from 'utils/dateUtils';

import style from './index.module.scss';

type TResultTest = {
  uid: string;
};

export enum EStageTask {
  NO_TASK = 'NOT_STARTED',
  GIVEN_TASK = 'GIVEN',
  STARTED_TASK = 'STARTED_TASK',
  ENDED_TASK = 'ENDED_TASK',
}

export const ResultTest: FC<TResultTest> = ({ uid }) => {
  const { data: solution, isLoading } = useGetSolutionByCandidateIdQuery(uid);
  const { data: candidate } = useGetCandidateByIdQuery(uid);
  const [createSolution, { data }] = usePostTaskSolutionMutation();
  const [time, setTime] = useState<string>('0');
  const [solutionLink, setSolutionLink] = useState<string>();
  const [isModal, setIsModal] = useState(false);


  const handleCopy = () => {
    if (solutionLink) {
      navigator.clipboard.writeText(solutionLink);
    }
    if (data) {
      setSolutionLink(`${window.location.hostname}/${routes.testingPage(data.id)}`);
    }
  };
  const handleCancel = () => {
    setIsModal(false);
  };
  const createTaskLink = (id: string) => {
    if (candidate) {
      createSolution({ techTaskId: id, candidateId: candidate.id });
    }
  };

  useEffect(() => {
    if (solution?.id) {
      setSolutionLink(`${window.location.hostname}/${routes.testingPage(solution.id)}`);
    }
    if (solution?.startedAt && solution?.endedAt) {
      const elapsed = Date.parse(solution.endedAt) - Date.parse(solution.startedAt);
      setTime(formatMsTo(elapsed, 'HH:mm:ss'));
    }
  }, [solution]);

  useEffect(() => {
    if (data && data.id) {
      setSolutionLink(`${window.location.hostname}/${routes.testingPage(data.id)}`);
      setIsModal(true);
    }
  }, [data]);

  const currentStages = () => {
    if (!solution) return <StagesTask.NoTask onSend={createTaskLink} />;
    if (solution.endedAt)
      return (
        <StagesTask.EndedTask>
          <TaskResult
            uid={uid}
            id={solution.id}
            time={time}
          />
        </StagesTask.EndedTask>
      );
    if (solution.startedAt) return <StagesTask.StartedTask link={solutionLink || ''} />;
    return <StagesTask.GivenTask link={solutionLink || ''} />;
  };

  return (
    <>
      {isLoading && <Loader />}
      {currentStages()}
      <Modal visible={isModal} footer={null} centered onCancel={handleCancel}>
        <div>
          <h3>Ссылка создана</h3>
          <span>Отправьте ссылку на тестовое задание кандидату</span>
          <div className={style.modal_footer}>
            <Input value={solutionLink} />
            <Button type="primary" onClick={handleCopy}>
              Скопировать
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
