import { api } from 'network/api/api';
import { apiRoutes } from 'network/apiRoutes';
import { TUser } from './types';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<TUser, void>({
      query: () => ({
        url: `${apiRoutes.USER}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserQuery } = userApi;
