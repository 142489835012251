import { createSlice } from '@reduxjs/toolkit';
import { userApi } from '.';
import { TUser } from './types';

const initialState: { user: TUser | null } = {
  user: {
    id: '',
    firstName: '',
    secondName: '',
    lastName: '',
    email: '',
    photo: '',
    role: '',
  },
};
// const initialState: TUser | object = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
