import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

import { ReactComponent as LaptopSvg } from 'assets/images/svg/laptop-01.svg';
import { Button } from 'components/ui/Button';

import { routes } from 'routing/routes';

import { getFromLocalStorage } from 'utils/localStorage/getFromLocalStorage';
import { isUserInterviewInfoObject } from 'utils/typeGuards';

import cx from './index.module.scss';
import { useGetCandidateByIdQuery } from 'store/candidate';
import { EStage } from 'constants/stageProgress';

export const EmptyResult = ({ uid }: { uid: string }) => {
  const hasInterview = !!getFromLocalStorage(uid, isUserInterviewInfoObject);
  const { data: candidate } = useGetCandidateByIdQuery(uid);

  const isStageAdded = candidate?.stage === EStage.ADDED;

  const subtitleText = isStageAdded ? 'Информации пока нет...' : 'Интервью прошло "без вопросов"';

  return (
    <div className={cx.empty_result_wrapper}>
      <Result
        className={cx.empty_result}
        subTitle={subtitleText}
        icon={<LaptopSvg />}
        extra={
          isStageAdded && (
            <Link to={routes.interviewPage(uid)}>
              <Button fixed type="primary">
                {hasInterview ? 'Продолжить интервью' : 'Начать интервью'}
              </Button>
            </Link>
          )
        }
      />
    </div>
  );
};
