/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { ReactComponent as IconCheck } from 'assets/images/svg/Check.svg';

import cx from './index.module.scss';

type TProps = {
  id: string;
  text: string;
  chosen: boolean;
  handleClick: (questionId: string, question: string) => void;
  focused: boolean;
};

export const QuestionsListElement = ({ id, text, chosen, handleClick, focused }: TProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (!chosen && event.code === 'Enter') {
        handleClick(id, text);
      }
    };
    if (focused) {
      if (!chosen) {
        document.body.addEventListener('keydown', handleEnter);
      }
      ref.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
    return () => {
      if (focused && !chosen) {
        document.body.removeEventListener('keydown', handleEnter);
      }
    };
  }, [focused]);

  return (
    <div
      ref={ref}
      className={cn(cx.root, chosen && cx.chosen, focused && cx.focused)}
      onClick={
        !chosen
          ? () => {
              handleClick(id, text);
            }
          : undefined
      }
    >
      <span className={cx.text}>{text}</span>
      {chosen && <IconCheck className={cx.check_svg} />}
    </div>
  );
};
