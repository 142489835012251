import { Rule } from 'antd/lib/form';

const onlyCyrillic = /^[А-яёЁ -]*$/;
const onlyCyrillicAndLat = /^[А-яё A-z -]*$/;
const onlyPhone = /^(\+7)(9[0-9]{9})$/;
const emailNoCyrilic = /^(?!.*[а-яё]).*$/i;

type TRules = {
  max: (maxNumber: number) => Rule;
  len: (length: number) => Rule;
  min: (minNumber: number) => Rule;
  pattern: (regexp: RegExp, errorMessage: string) => Rule;
  required: Rule;
  patternCyrillic: Rule;
  patternCyrillicAndLat: Rule;
  email: Rule;
  emailValidation: Rule;
  emailNoCyrilic: Rule;
  patternPhone: Rule;
  whitespace: Rule;
};

export const rules: TRules = {
  max: (maxNumber) => ({
    max: maxNumber,
    message: `Максимальное количество символов - ${maxNumber}`,
  }),
  len: (length) => ({
    len: length,
    message: `Длина должна быть ${length} символов`,
  }),
  min: (minNumber) => ({
    min: minNumber,
    message: `Минимальное количество символов - ${minNumber}`,
  }),
  pattern: (regexp, errorMessage) => ({
    pattern: regexp,
    message: errorMessage,
  }),
  required: {
    required: true,
    message: 'Заполните обязательное поле',
  },
  patternCyrillic: {
    pattern: onlyCyrillic,
    message: 'Только кириллица',
  },
  patternCyrillicAndLat: {
    pattern: onlyCyrillicAndLat,
    message: 'Поле заполнено некорректно',
  },
  email: {
    type: 'email',
    message: 'Поле заполнено некорректно',
  },
  emailValidation: {
    message: 'Поле заполнено некорректно',
    validator: (_, value) => {
      if (value) {
        const findMatch = value.match(/(?<=@)[^.]+(?=\.)/);
        const domenName = findMatch[0];
        if (domenName[0] === '-' || domenName[domenName.length - 1] === '-') {
          return Promise.reject('Поле заполнено некорректно');
        }
      }

      return Promise.resolve();
    },
  },
  emailNoCyrilic: {
    pattern: emailNoCyrilic,
    message: 'Поле заполнено некорректно',
  },
  patternPhone: {
    pattern: onlyPhone,
    message: 'Возможно, допущена ошибка в номере',
  },
  whitespace: {
    whitespace: true,
    message: 'Поле заполнено некорректно',
  },
};
