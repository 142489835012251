import { api } from 'network/api/api';
import { EApiTags } from 'network/api/types';
import { apiRoutes } from 'network/apiRoutes';
import { TCandidate, TFormValues } from './types';

export const candidateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addCandidate: builder.mutation<TCandidate, Partial<TFormValues>>({
      query: (body) => ({
        url: `${apiRoutes.CANDIDATE}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EApiTags.CANDIDATE],
    }),
    getCandidateById: builder.query<TCandidate, string>({
      query: (id) => ({
        url: `${apiRoutes.CANDIDATE}/${id}`,
        method: 'GET',
      }),
      providesTags: (_res, _err, id) => [{ id, type: EApiTags.CANDIDATE }],
    }),
    deleteCandidateById: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `${apiRoutes.CANDIDATE}/${id}`,
        method: 'DELETE',
      }),
    }),
    editCandidateById: builder.mutation<TCandidate, Partial<TFormValues>>({
      query: ({ id, ...body }) => ({
        url: `${apiRoutes.CANDIDATE}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_res, _err, { id }) => [
        { id, type: EApiTags.CANDIDATE },
      ],
    }),
  }),
});

export const {
  useDeleteCandidateByIdMutation,
  useGetCandidateByIdQuery,
  useLazyGetCandidateByIdQuery,
  useAddCandidateMutation,
  useEditCandidateByIdMutation,
} = candidateApi;
