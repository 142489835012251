import React from 'react';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { menuItems } from './menuConfig';

import cx from './index.module.scss';

export const MainMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuSelect = ({ key }) => {
    navigate(key);
  };

  const keysPath = location.pathname.match(/\/[a-z0-9-]+/gi);

  return (
    <Menu
      className={cx.menu}
      items={menuItems}
      onSelect={onMenuSelect}
      selectedKeys={keysPath || ['/']}
    />
  );
};
