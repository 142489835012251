import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Radio } from 'antd';

import { ReactComponent as EditBoxSvg } from 'assets/images/svg/edit-box2.svg';
import ScoreTag from './ScoreTag';
import { EmptyResult } from './EmptyResult';
import { QuestionList } from './QuestionList';
import { Loader } from 'components/ui/Loader';

import { useGetInterviewResultQuery } from 'store/interview';
import { routes } from 'routing/routes';
import { TGetInterviewQuestion, TGetInterviewResultResponse } from 'store/interview/types';
import { RadioChangeEvent } from 'antd/lib/radio';

import cx from './index.module.scss';

export type TResultBlock = TGetInterviewResultResponse[number];
type TFilterOptions = {
  label: string;
  value: number;
};

const BLOCK_TECH_QUESTION_ID = 13;

export const ResultInterview = ({ uid }: { uid: string }) => {
  const { data, isError, isLoading, isSuccess } = useGetInterviewResultQuery(uid);
  const [filter, setFilter] = React.useState<TResultBlock['id']>(0);
  const resultBlocks: TResultBlock[] = data ? [...data].sort((a, b) => a.id - b.id) : []; //  эта для develop

  const filterOptions: TFilterOptions[] = resultBlocks.map((block) => ({
    label: block.title,
    value: block.id,
  }));

  const onFilterChange = (e: RadioChangeEvent) => {
    setFilter(e.target.value);
  };

  const getQuestionsByBlockId = (blockId: TResultBlock['id']): TResultBlock['questions'] =>
    resultBlocks.find((block) => block.id === blockId)?.questions || [];

  const sumScore = () =>
    (20 *
      getQuestionsByBlockId(BLOCK_TECH_QUESTION_ID).reduce(
        (acc, { results }) => acc + (results[0].score || 0),
        0,
      )) /
    getQuestionsByBlockId(BLOCK_TECH_QUESTION_ID).length;

  const getQuestionsByFilter = (filter: TResultBlock['id']) =>
    filter === 0
      ? resultBlocks.reduce<TGetInterviewQuestion[]>(
          (questions, block) => questions.concat(block.questions),
          [],
        )
      : getQuestionsByBlockId(filter);

  return (
    <>
      {isLoading && <Loader />}
      {isError && <div>Error </div>}
      {isSuccess && !!resultBlocks.length ? (
        <div className={cx.interview_content}>
          <div className={cx.tools}>
            <Radio.Group
              options={[{ label: 'Все', value: 0 }, ...filterOptions]}
              value={filter}
              onChange={onFilterChange}
            />
            <Link to={`${routes.interviewEdit(uid)}`}>
              <EditBoxSvg />
            </Link>
          </div>

          <div className={cx.questions_list}>
            <QuestionList questions={getQuestionsByFilter(filter)} />
          </div>
          <Divider type="horizontal" />
          <div className={cx.summary}>
            <span className={cx.label}> Результат:</span>
            <ScoreTag count={+sumScore().toFixed(1) || 0} percent />
          </div>
        </div>
      ) : (
        <EmptyResult uid={uid} />
      )}
    </>
  );
};
