import React from 'react';
import { Spin } from 'antd';

import cx from './index.module.scss';

export const Loader = (props) => (
  <div className={cx.spin}>
    <Spin {...props} />
  </div>
);
