import React from 'react';
import { Result } from 'antd';

import { ReactComponent as LaptopSvg } from 'assets/images/svg/laptop-01.svg';
import { Button } from 'components/ui/Button';

import cx from './empty.module.scss';

type TProps = { subtitleText: string; link: string };

export const EmptyTask = ({ subtitleText, link }: TProps) => {
  const buttonClick = (): void => {
    navigator.clipboard.writeText(link);
  };

  return (
    <div className={cx.empty_result_wrapper}>
      <Result
        className={cx.empty_result}
        subTitle={subtitleText}
        icon={<LaptopSvg />}
        extra={
          <Button type="primary" fixed onClick={buttonClick}>
            Копировать ссылку
          </Button>
        }
      />
    </div>
  );
};
