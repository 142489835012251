import React, { FC } from 'react';
import { ReactComponent as RewindSVG } from 'assets/images/svg/player_rewind.svg';
import { ReactComponent as PlaySVG } from 'assets/images/svg/player_play.svg';
import { ReactComponent as PauseSVG } from 'assets/images/svg/player_pause.svg';
import style from './index.module.scss';

type TControls = {
  handlePlay: () => void;
  handleRewind: (type: 'prev' | 'next') => void;
  isPause: boolean;
};

export const Controls: FC<TControls> = ({ handlePlay, handleRewind, isPause }) => (
  <div className={style.controls}>
    <button type="button" className={style.rewind} onClick={() => handleRewind('prev')}>
      <RewindSVG />
    </button>
    <button type="button" className={style.play} onClick={handlePlay}>
      {isPause ? <PlaySVG /> : <PauseSVG />}
    </button>
    <button type="button" className={style.forward} onClick={() => handleRewind('next')}>
      <RewindSVG />
    </button>
  </div>
);
