import React from 'react';
import cn from 'classnames';

import { ReactComponent as SettingsSvg } from 'assets/images/svg/settings.svg';

import cx from './index.module.scss';

type TSettingsProps = {
  className?: string;
};

export const Settings = ({ className }: TSettingsProps) => (
  <SettingsSvg className={cn(cx.icon, className)} />
);
