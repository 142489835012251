import React, { useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Skeleton } from 'antd';

import { ExitButton } from './ExitButton';

import { useGetUserQuery } from 'store/user';
import { hint } from 'components/ui/Hint';
import { useAppDispatch } from 'store/hooks';
import { logout } from 'store/auth/slice';
import { chooseCandidateAvatarByID } from 'utils/avatar';

import cx from './index.module.scss';

const content = (
  <div className={cx.container}>
    <ExitButton />
  </div>
);

export const UserAvatar = () => {
  const { data, isError, isSuccess } = useGetUserQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      hint.error('Ошибка получения данных пользователя');
      dispatch(logout());
    }
  }, [isError]);

  if (isSuccess) {
    const avatarIcon = chooseCandidateAvatarByID(5);
    return (
      <Popover trigger="click" content={content} overlayClassName={cx.popover}>
        <div className={cx.avatar}>
          {data.photo ? (
            <img src={data.photo} alt="user avatar" className={cx.image} />
          ) : (
            <Avatar size={44} src={avatarIcon} icon={<UserOutlined />} />
          )}
        </div>
      </Popover>
    );
  }
  return <Skeleton.Avatar size={44} active className={cx.skeleton_avatar} />;
};
