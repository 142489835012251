import React from 'react';
import { Collapse } from 'antd';

import { ReactComponent as ToDownSvg } from 'assets/images/svg/to-down.svg';
import ScoreTag from './ScoreTag';

import { TResultBlock } from '.';

import cx from './index.module.scss';

const renderExpandIcon = () => <ToDownSvg />;
export const QuestionList = ({ questions }: Pick<TResultBlock, 'questions'>) => {
  const renderQuestionItem = (question: TResultBlock['questions'][number]) => (
    <Collapse.Panel
      className={cx.question}
      key={question.id}
      header={question.text}
      extra={!!question.results[0].score && <ScoreTag count={question.results[0].score} />}
      collapsible={question.results[0].comment ? undefined : 'header'}
      showArrow={!!question.results[0].comment}
    >
      {question.results[0].comment}
    </Collapse.Panel>
  );

  return (
    <Collapse expandIconPosition="end" expandIcon={renderExpandIcon} ghost>
      {questions.map(renderQuestionItem)}
    </Collapse>
  );
};
