import React, { FC, useEffect, useRef, useState } from 'react';

import { CurrentMark } from '../CurrentMark';

import { TTechTaskStamp } from 'store/techTask/types';

import style from './index.module.scss';

type TMarker = {
  mark: TTechTaskStamp;
  totalTime: number;
  onClick?: (e: React.MouseEvent<HTMLOptionElement>) => void;
  isCurrent: boolean;
};

export const Mark: FC<TMarker> = ({ onClick, mark, totalTime, isCurrent }: TMarker) => {
  const { eventName, timeSincePrevStamp, createdAt, addingData } = mark;
  const [left, setLeft] = useState<number>(0);
  const ref = useRef<HTMLOptionElement | null>(null);
  useEffect(() => {
    if (ref.current && isCurrent) {
      setLeft(ref.current.offsetLeft + ref.current.offsetWidth / 2);
    }
  }, [isCurrent]);

  const calculateWidth = () => {
    if (addingData && eventName !== 'paste') {
      const timeSpent = createdAt - +addingData;

      return `${(timeSpent / totalTime) * 100}%`;
    }

    return `10px`;
  };

  const calculateLeft = () => (timeSincePrevStamp / totalTime) * 100;
  const getColor = () => {
    if (eventName === 'debounce') {
      return '#C1C6D7';
    }
    if (eventName === 'paste') {
      return '#FE3D01';
    }
    return '#CCD7FF';
  };

  return (
    <>
      <option
        ref={ref}
        label=" "
        value={mark.createdAt}
        onClick={onClick}
        className={style.option}
        style={{
          minWidth: calculateWidth(),
          width: calculateWidth(),
          backgroundColor: `${getColor()}`,
          marginLeft: `${calculateLeft()}%`,
        }}
      />

      {isCurrent && left !== 0 && <CurrentMark leftPx={left} />}
    </>
  );
};
