import { useEffect, useState } from 'react';

export const useTimer = (stop: boolean, startedTimestamp = Date.now()): number => {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    let timer;
    if (!stop) {
      timer = setInterval(() => {
        setTime(Date.now());
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const timeAfterStart = time - startedTimestamp + 1000;
  return timeAfterStart;
};
