import React, { FC } from 'react';

import styles from './index.module.scss';

type TCurrentMark = {
  leftPx: number | undefined;
};

export const CurrentMark: FC<TCurrentMark> = ({ leftPx }) => (
  <div className={styles.wrapper} style={{ left: `${leftPx}px` }}>
    <div className={styles.ellipse}>
      <div className={styles.line} />
    </div>
  </div>
);
