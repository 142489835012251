import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ECandidatesListOrderColumnName,
  TCandidatesListFilterArgs,
  TCandidatesListPaginationArgs,
  TCandidatesListSelection,
  TCandidatesListSortArgs,
} from './types';

export const CANDIDATES_LIST_SLICE = 'candidatesList' as const;

export type TSliceCandidatesList = {
  filters: TCandidatesListFilterArgs;
  pagination: TCandidatesListPaginationArgs;
  sort: TCandidatesListSortArgs;
  selection: TCandidatesListSelection;
}

const initialState: TSliceCandidatesList = {
  filters: {},
  pagination: {
    take: 6,
    skip: 0,
  },
  sort: {
    orderColumnName: ECandidatesListOrderColumnName.STAGE,
    order: 'ASC',
  },
  selection: {
    list: [],
    showOnlySelected: false,
  },
};

export const candidatesListSlice = createSlice({
  name: CANDIDATES_LIST_SLICE,
  initialState,
  reducers: {
    // Filter
    setFilterByName(state, { payload }: PayloadAction<TCandidatesListFilterArgs['filterByNameValue']>) {
      state.filters.filterByNameValue = payload || undefined;
      state.pagination.skip = 0;
    },
    setStartDate(state, { payload }: PayloadAction<TCandidatesListFilterArgs['startDate']>) {
      state.filters.startDate = payload || undefined;
      if (payload && !state.filters.endDate) {
        state.filters.endDate = payload;
      }
      state.pagination.skip = 0;
    },
    setEndDate(state, { payload }: PayloadAction<TCandidatesListFilterArgs['endDate']>) {
      state.filters.endDate = payload || undefined;
      if (payload && !state.filters.startDate) {
        state.filters.startDate = payload;
      }
      state.pagination.skip = 0;
    },
    setFilterByDirection(state, { payload }: PayloadAction<TCandidatesListFilterArgs['filterByDirection']>) {
      state.filters.filterByDirection = payload?.length ? payload : undefined;
      state.pagination.skip = 0;
    },
    setFilterByStage(state, { payload }: PayloadAction<TCandidatesListFilterArgs['filterByStage']>) {
      state.filters.filterByStage = payload?.length ? payload : undefined;
      state.pagination.skip = 0;
    },
    setFilterByStatus(state, { payload }: PayloadAction<TCandidatesListFilterArgs['filterByStatus']>) {
      state.filters.filterByStatus = payload?.length ? payload : undefined;
      state.pagination.skip = 0;
    },
    setFilterByVacancy(state, { payload }: PayloadAction<TCandidatesListFilterArgs['filterByVacancy']>) {
      state.filters.filterByVacancy = payload?.length ? payload : undefined;
      state.pagination.skip = 0;
    },
    setFilterByLevel(state, { payload }: PayloadAction<TCandidatesListFilterArgs['filterByLevel']>) {
      state.filters.filterByLevel = payload?.length ? payload : undefined;
      state.pagination.skip = 0;
    },

    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.selection = initialState.selection;
    },

    // Sort
    setSort(state, { payload }: PayloadAction<TCandidatesListSortArgs>) {
      state.sort = payload;
    },
    setSortOrder(state, { payload }: PayloadAction<TCandidatesListSortArgs['order']>) {
      state.sort.order = payload;
    },
    setSortColumnName(state, { payload }: PayloadAction<TCandidatesListSortArgs['orderColumnName']>) {
      state.sort.orderColumnName = payload;
    },

    // Pagination
    setPageSize(state, { payload }: PayloadAction<number>) {
      state.pagination.take = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.pagination.skip = state.pagination.take * (payload - 1);
    },

    // Selection
    setSelectedRows(state, { payload }: PayloadAction<TCandidatesListSelection['list']>) {
      state.selection.list = payload;
    },
    setShowOnlySelected(state, { payload }: PayloadAction<boolean>) {
      state.selection.showOnlySelected = payload;
      state.pagination.skip = 0;
    },
  },
});

export const CandidatesListActions = candidatesListSlice.actions;
export default candidatesListSlice.reducer;