import React, { useEffect, useState } from 'react';
import { Input, List, Typography } from 'antd';

import { Button } from 'components/ui/Button';
import { hint } from 'components/ui/Hint';
import { ReactComponent as IconSend } from 'assets/images/svg/send.svg';
import { ListItem } from './ListItem';
import { EmptyCommentsList } from './EmptyCommentsList';

import { TCommentPostBody } from 'store/commentsAboutCandidate/types';
import {
  useGetCommentsAboutCandidateQuery,
  useAddCommentAboutCandidateMutation,
} from 'store/commentsAboutCandidate';
import { useAppSelector } from 'store/hooks';
import { getUserId } from 'store/user/selectors';

import cx from './index.module.scss';

const { Title } = Typography;
const { TextArea } = Input;

type TProps = { uid: string };

export const CommentsAboutCandidate = ({ uid }: TProps) => {
  const { data, isError, isLoading, isSuccess, isFetching } =
    useGetCommentsAboutCandidateQuery(uid);
  const [addComent, { isError: errorAddComment }] = useAddCommentAboutCandidateMutation();
  const userId = useAppSelector(getUserId);

  const [newComment, setNewComment] = useState<string>('');
  const [isMute, setIsMute] = useState<boolean>(true);

  const comments = isSuccess ? data : [];

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    const isMute = value.trim().length < 2;
    setIsMute(isMute);
    setNewComment(value);
  };

  const handleSend = () => {
    if (isMute || !userId) return;
    const comment = newComment.trim();
    const body: TCommentPostBody = {
      userId,
      candidateId: uid,
      text: comment,
    };
    setNewComment('');
    addComent(body).unwrap();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();
    if (e.shiftKey) {
      setNewComment((comment) => `${comment}\n`);
    } else {
      handleSend();
    }
  };

  useEffect(() => {
    if (errorAddComment) hint.error('Ошибка отправки комментария');
  }, [errorAddComment]);

  return (
    <div className={cx.container}>
      <Title level={4} className={cx.title_4}>
        Комментарии
      </Title>
      {comments.length ? (
        <List
          itemLayout="vertical"
          dataSource={comments}
          loading={isLoading || isFetching}
          renderItem={(item) => <ListItem item={item} />}
        />
      ) : (
        <EmptyCommentsList isError={isError} />
      )}

      <div className={cx.send_comment}>
        <TextArea
          placeholder="Написать комментарий..."
          autoSize={{ minRows: 1, maxRows: 6 }}
          showCount
          maxLength={1000}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={newComment}
        />
        <div className={cx.send_button}>
          <Button
            onlyIcon="R"
            type="primary"
            onClick={handleSend}
            style={{ width: '40px', height: '40px' }}
            disabled={isMute}
          >
            <IconSend />
          </Button>
        </div>
      </div>
    </div>
  );
};
