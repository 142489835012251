import { api } from "network/api/api";
import { EApiTags } from "network/api/types";
import { apiRoutes } from "network/apiRoutes";
import { TCreateCustomQuestionsRequest, TCreateCustomQuestionsResponse, TEditInterviewResultRequest, TGetInterviewResultResponse, TInterviewBlock, TPostInterviewRequest, TQuestion } from "./types";

export const interviewApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getInterviewBlocks: builder.query<TInterviewBlock[], void>({
            query: () => ({
                url: apiRoutes.INTERVIEW_BLOCKS,
            }),
        }),
        getInterviewQuestions: builder.query<TQuestion[], string>({
            query: (id) => ({
                url: `${apiRoutes.QUESTIONS}/${id}`
            })
        }),
        postInterviewResult: builder.mutation<unknown, { body: TPostInterviewRequest, uid: string }>({
            query: ({ body, uid }) => ({
                url: `${apiRoutes.RESULTS_INTERVIEW}/${uid}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, args) => [{ id: args.uid, type: EApiTags.INTERVIEW }, { id: args.uid, type: EApiTags.CANDIDATE }]
        }),
        createCustomQuestions: builder.mutation<TCreateCustomQuestionsResponse, TCreateCustomQuestionsRequest>({
            query: (body) => ({
                url: `${apiRoutes.CUSTOM_QUESTIONS}`,
                method: 'POST',
                body,
            }),
        }),
        deleteCustomQuestions: builder.mutation<TCreateCustomQuestionsResponse, string>({
            query: (uid) => ({
                url: `${apiRoutes.CUSTOM_QUESTIONS}/${uid}`,
                method: 'DELETE',
            }),
        }),
        editInterviewResult: builder.mutation<unknown, { body: TEditInterviewResultRequest, uid: string }>({
            query: ({ body, uid }) => ({
                url: `${apiRoutes.RESULTS_INTERVIEW}/${uid}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, { uid }) => [{ id: uid, type: EApiTags.INTERVIEW }, {id: uid,  type: EApiTags.CANDIDATE }]
        }),
        getInterviewResult: builder.query<TGetInterviewResultResponse, string>({
            query: (uid) => ({
                url: `${apiRoutes.RESULTS_INTERVIEW}/${uid}`,
            }),
            providesTags: (result, error, uid) => [{ id: uid, type: EApiTags.INTERVIEW }]
        }),
    }),
})

export const { useGetInterviewBlocksQuery, useGetInterviewQuestionsQuery, useDeleteCustomQuestionsMutation,
    usePostInterviewResultMutation, useCreateCustomQuestionsMutation, useEditInterviewResultMutation, useGetInterviewResultQuery } = interviewApi;
