import AvatarMushroom from 'assets/images/svg/avatar_mushroom_64.svg';
import AvatarPanda from 'assets/images/svg/avatar_panda_64.svg';
import AvatarGinger from 'assets/images/svg/avatar_ginger_64.svg';
import AvatarDuck from 'assets/images/svg/avatar_duck_64.svg';
import AvatarJake from 'assets/images/svg/avatar_jake_64.svg';
import AvatarSkywalker from 'assets/images/svg/avatar_skywalker_64.svg';

const candidateAvatars = [
  AvatarMushroom,
  AvatarPanda,
  AvatarGinger,
  AvatarDuck,
  AvatarJake,
  AvatarSkywalker,
];

// Временная функция для выбора одной из хранящихся на фронте аватарок
export const chooseCandidateAvatarByID = (id: number | string) => {
  // Приведение строки к числу
  if (typeof id === 'string') {
    let accNum = 0;
    for (let i = 0; i < id.length; i += 1) {
      accNum += id.charCodeAt(i);
    }
    id = accNum;
  }
  // Приведение числа к заданному диапазону
  const i = id % candidateAvatars.length;
  return candidateAvatars[i];
};
