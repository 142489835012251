import React from 'react';
import { message } from 'antd';

import { ReactComponent as IconSuccess } from 'assets/images/svg/hint-verify-circle.svg';
import { ReactComponent as IconError } from 'assets/images/svg/hint-circle-error.svg';
import { ReactComponent as IconInfo } from 'assets/images/svg/hint-warning.svg';

message.config({ duration: 2 });

export const hint = {
  success: (text) => {
    message.success({ content: text, icon: <IconSuccess /> });
  },
  error: (text) => {
    message.error({ content: text, icon: <IconError /> });
  },
  info: (text) => {
    message.info({ content: text, icon: <IconInfo /> });
  },
};
