import UniversalCookie from 'universal-cookie';

class Cookies extends UniversalCookie {
    setToken = (token: string) => {
        const expires = new Date();
        expires.setDate(expires.getDate() + 30);
        this.set('token', token, { path: '/', expires });
    };

    deleteToken = () => {
        this.remove('token');
    };

    getToken = () => this.get('token');
}

export const clientCookies = new Cookies();