import React from 'react';
import { Typography } from 'antd';

import { CodeEditor } from 'components/ui/CodeEditor';

import { TStampEvent } from 'store/techTask/types';

import cx from './index.module.scss';

const { Paragraph, Title } = Typography;

type TProps = {
  stampCounter?: number;
  techTaskId: string | number;
  startDebounce: number;
  setStartDebounce: React.Dispatch<React.SetStateAction<number>>;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  addStamp: (value: TStampEvent, addingData?: string | number) => void;
  code: string;
  description?: string;
  disabled: boolean;
};

export const TaskSpace = (props: TProps) => {
  const { code, setCode, addStamp, description, startDebounce, setStartDebounce, disabled } = props;

  const handleChange = (value: string) => {
    if (startDebounce === 0) {
      setStartDebounce(Date.now());
    }
    setCode(value);
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const paste = event.clipboardData.getData('text');
    addStamp('paste', paste);
  };

  return (
    <main className={cx.main}>
      <section className={cx.side_instruction}>
        <div className={cx.task_container}>
          <div className={cx.task_section}>
            <Title level={3} className={cx.task_title}>
              Задание
            </Title>
            <Paragraph className={cx.task_text}>{description}</Paragraph>
          </div>
        </div>
      </section>
      <section className={cx.side_code_editor}>
        <CodeEditor value={code} {...{ handleChange, handlePaste, disabled }} />
      </section>
    </main>
  );
};
