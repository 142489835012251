import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import { Instruction } from './Instruction';
import { TaskSpace } from './TaskSpace';
import { HeaderTesting } from './HeaderTesting';
import { CompleteTask } from './CompleteTask';

import { dateNow } from 'utils/dateUtils';
import { TDataForTechTask, TStampEvent, TTechTaskStamp } from 'store/techTask/types';
import { useDebounce } from 'utils/hooks/useDebounce';
import { usePostStampMutation, useUpdateSolutionMutation } from 'store/techTask';

import cx from './index.module.scss';

const { Header, Content } = Layout;

const placeholderMessage = `// нужен запрос задачи от бэка
// нужен запрос на изменение сущности ответа
// - при нажатии начать отправить время начала
// - при событии отпарвлять stamp
// - по завершении отправить время окончания
// - по завершении запретить дальнейшие запросы на изменение
`;

type TProps = TDataForTechTask;

export const TechTaskEditor = (props: TProps) => {
  const { solution, task, lastStamp: lastStampFromBack } = props;
  const {
    id: techSolutionId,
    techTaskId,
    startedAt: startedAtBack,
    endedAt: endedAtBack,
  } = solution;

  const { timeLimit, description } = task;

  const [sendStamp] = usePostStampMutation();
  const [updateSoulution, { isSuccess: isSuccessUpdate }] = useUpdateSolutionMutation();

  const [startedAt, setStartedAt] = useState<number>(Date.parse(startedAtBack || ''));
  const [endedAt, setEndedAt] = useState<number>(Date.parse(endedAtBack || ''));
  const [lastStamp, setLastStamps] = useState(lastStampFromBack);
  const [stampsArray, setStampsArray] = useState<TTechTaskStamp[]>([]); // FIXME для разработки позже удалить
  const [code, setCode] = useState<string>(lastStamp?.code || placeholderMessage);
  const [startDebounce, setStartDebounce] = useState<number>(0);
  const [isSuccesfullSend, setSuccesfullSend] = useState<boolean>(!!endedAt);

  useEffect(() => {
    if (isSuccessUpdate && endedAt) {
      setSuccesfullSend(true);
    }
  }, [isSuccessUpdate, endedAt]);

  const [isFocus, setIsFocus] = useState<boolean>(true);

  const addStamp = (eventName: TStampEvent, addingData?: TTechTaskStamp['addingData']) => {
    if (endedAt || !startedAt) return;
    const time = Date.now();
    const previousTime = lastStamp?.createdAt || time;
    const timeSincePrevStamp = time - previousTime;
    const newStamp: TTechTaskStamp = {
      techSolutionId,
      code,
      createdAt: time,
      timeSincePrevStamp,
      eventName,
      addingData,
    };
    if (newStamp) {
      sendStamp(newStamp);
      setLastStamps(newStamp);
    }
    setStampsArray([...stampsArray, newStamp]); // FIXME для разработки позже удалить
  };

  const completeTask = () => {
    setEndedAt(Date.now());
    updateSoulution({ id: techSolutionId, endedAt: dateNow() });
  };

  const readInstruction = () => {
    setStartedAt(Date.now());
    updateSoulution({ id: techSolutionId, startedAt: dateNow() });
  };
  const debounceCode = useDebounce<string>(code, 1000);

  useEffect(() => {
    if (startDebounce) {
      addStamp('debounce', String(startDebounce));
    }
    setStartDebounce(0);
  }, [debounceCode]);

  useEffect(() => {
    const eventName: TStampEvent = isFocus ? 'focus' : 'blur';
    addStamp(eventName);
  }, [isFocus]);

  const renderContent = () => {
    if (isSuccesfullSend) {
      return <CompleteTask />;
    }
    if (startedAt) {
      return (
        <TaskSpace
          {...{
            techTaskId,
            setCode,
            addStamp,
            code,
            description,
            setStartDebounce,
            startDebounce,
            disabled: !!endedAt,
          }}
        />
      );
    }
    return <Instruction read={readInstruction} />;
  };

  return (
    <Layout className={cx.layout} onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)}>
      <Header className={cx.header}>
        <HeaderTesting {...{ startedAt, endedAt, timeLimit, completeTask }} />
      </Header>

      <Content className={cx.content}>{renderContent()}</Content>
    </Layout>
  );
};
