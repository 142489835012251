import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { routes } from 'routing/routes';
import { getIsAuth } from 'store/auth/selectors';
import { useAppSelector } from 'store/hooks';

export const PrivatePageHOC: FC<{ children?: React.ReactElement }> = ({ children }) => {
  const isAuth = useAppSelector(getIsAuth);
  if (!isAuth) {
    return <Navigate to={routes.auth} />;
  }
  return children || <Outlet />;
};
