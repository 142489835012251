import React from 'react';
import { Form } from 'antd';

import { QuestionEdit } from 'components/InterviewElements/Question/QuestionEdit';

import { TInterviewBlock } from 'store/interview/types';

import cx from '../index.module.scss';

type TProps = TInterviewBlock & { number: number; technical: boolean };

export const BlockEdit = ({ title, id, number, technical }: TProps) => (
  <div className={cx.root}>
    <div className={cx.line} />
    <span className={cx.block_name}>{`Блок ${number}. ${title}`}</span>
    <Form.List name={id}>
      {(fields) => (
        <div>
          {fields.map((field) => (
            <QuestionEdit
              blockId={`${id}`}
              serialNumber={field.name}
              key={field.key}
              technical={technical}
            />
          ))}
        </div>
      )}
    </Form.List>
  </div>
);
