import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

import { RoutingConfig } from 'routing/RoutingConfig';

export const Path = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(RoutingConfig);

  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb) => (
        <Breadcrumb.Item key={breadcrumb.key}>
          <Link to={breadcrumb.match.pathname}>{breadcrumb.breadcrumb}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
