import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { TRouterParams } from 'routing/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPartialSubmitCancelledStatus } from 'store/interview/selectors';
import { setQuestionScore } from 'store/interview/slice';
import { TInterviewQuestion } from 'store/interview/types';

import cx from './index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

type TProps = {
  blockId: string;
  serialNumber: number;
  technical: boolean;
};

export const SCORE_OPTIONS = [1, 2, 3, 4, 5];

export const Question = ({ blockId, serialNumber, technical }: TProps) => {
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const form = Form.useFormInstance();
  const questionValues = Form.useWatch([blockId, serialNumber], form);
  const fieldValue: TInterviewQuestion = form.getFieldValue([blockId, serialNumber]);
  const localStorageId = `${uid}_${fieldValue.id}`;
  const dispatch = useAppDispatch();
  const isPartialSubmitCancelled = useAppSelector(getPartialSubmitCancelledStatus);

  useEffect(() => {
    localStorage.setItem(localStorageId, JSON.stringify(questionValues));
  }, [questionValues]);

  const handleSelectChange = (e) => {
    dispatch(setQuestionScore({ name: fieldValue.id, value: e }));
  };

  return (
    <div className={cx.root}>
      <div className={cx.question_and_score}>
        <Form.Item
          label={`Вопрос ${serialNumber + 1}`}
          className={cn(cx.question, !technical && cx.non_tech)}
          name={[serialNumber, 'question']}
          rules={[{ required: isPartialSubmitCancelled && fieldValue.custom, message: '' }]}
        >
          <TextArea
            size="large"
            maxLength={1000}
            placeholder="Введите свой вопрос"
            disabled={!fieldValue.custom}
            autoSize={{ maxRows: 4 }}
            className={cx.text_area}
          />
        </Form.Item>
        {technical && (
          <Form.Item
            label="Балл за ответ"
            className={cx.score}
            name={[serialNumber, 'score']}
            rules={[{ required: isPartialSubmitCancelled, message: '' }]}
          >
            <Select size="large" placeholder="Укажите балл" onChange={handleSelectChange}>
              <Option value={null}>{null}</Option>
              {SCORE_OPTIONS.map((item) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>
      <Form.Item
        label="Комментарий"
        className={cx.comments}
        name={[serialNumber, 'comment']}
        rules={[{ required: !technical && isPartialSubmitCancelled, message: '' }]}
      >
        <TextArea placeholder="Написать комментарий..." showCount maxLength={1000} rows={4} />
      </Form.Item>
    </div>
  );
};
