/* eslint-disable react/no-children-prop */
import React from 'react';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { params, routes } from 'routing/routes';
import { PrivatePageHOC } from 'routing/PrivatePageHOC';
import {
  AuthPage,
  NotFoundPage,
  CandidateProfilePage,
  CandidatesListPage,
  EditCandidatePage,
  NewCandidatePage,
  InterviewEditPage,
  TechTaskWatchPage,
  InterviewPage,
  TestingPage,
} from 'pages';
import { MainLayout } from 'components/MainLayout';
import { CandidatesList } from 'components/CandidatesList';
import { CandidateName } from 'components/MainLayout/Path/BreadcrumbsHeaders';
import { MainPage } from 'pages/MainPage';

export const RoutingConfig: BreadcrumbsRoute[] = [
  {
    path: routes.auth,
    element: <AuthPage />,
  },
  {
    breadcrumb: 'Главная',
    path: routes.root,
    element: <PrivatePageHOC children={<MainLayout />} />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        breadcrumb: 'Список кандидатов',
        path: routes.candidatesList,
        element: <CandidatesListPage />,
        children: [
          {
            index: true,
            element: <CandidatesList />,
          },
          {
            breadcrumb: 'Редактирование профиля',
            path: routes.candidateEdit(params.candidateId),
            element: <EditCandidatePage />,
          },
          {
            breadcrumb: CandidateName,
            path: routes.candidateProfile(params.candidateId),
            element: <CandidateProfilePage />,
          },
          {
            breadcrumb: 'Редактирование интервью',
            path: routes.interviewEdit(params.candidateId),
            element: <InterviewEditPage />,
          },
          {
            breadcrumb: 'Техническое задание',
            path: routes.techTaskWatch(params.candidateId, params.solutionId),
            element: <TechTaskWatchPage />,
          },
        ],
      },
      {
        breadcrumb: 'Регистрация кандидата',
        path: routes.newCandidate,
        element: <NewCandidatePage />,
      },
      {
        breadcrumb: 'Интервью',
        path: routes.interviewPage(params.candidateId),
        element: <InterviewPage />,
      },
    ],
  },
  {
    breadcrumb: 'Тестирование',
    path: routes.testingPage(params.solutionId),
    element: <TestingPage />,
  },
  {
    path: routes.notFound,
    element: <NotFoundPage />,
  },
];
