import React from 'react';
import { Select } from 'antd';
import { useEditCandidateByIdMutation, useGetCandidateByIdQuery } from 'store/candidate';

import { score } from 'constants/score';

import cx from './index.module.scss';

type TProps = {
  pasteCount: number;
  time: string;
  uid: string;
};

export const TaskResultInfo = (props: TProps) => {
  const { pasteCount, time, uid } = props;
  const { data: candidate } = useGetCandidateByIdQuery(uid);
  const [editCandidate] = useEditCandidateByIdMutation();

  const sendNewTechTaskScore = (value: number) => {
    editCandidate({ id: uid, ...{ techTaskScore: value } });
  };

  return (
    <div className={cx.info}>
      <div className={cx.item}>
        <span className={cx.title}>Время выполнения </span>
        <span className={cx.details}>{time}</span>
      </div>

      <div className={cx.item}>
        <span className={cx.title}>Балл за задание</span>
        <span className={cx.details}>
          <Select
            className={cx.select}
            dropdownMatchSelectWidth
            defaultValue={candidate?.techTaskScore || 0}
            onSelect={(value: number) => sendNewTechTaskScore(value)}
          >
            {score.map((value) => (
              <Select.Option key={value} value={+value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </span>
      </div>
      <div className={cx.item}>
        <span className={cx.title}>Вставок кода</span>
        <span className={cx.details}>{pasteCount}</span>
      </div>
    </div>
  );
};
