import moment from 'moment';

type TFormatDate = (date: string) => string;

export const formatDateDDMMYYYY: TFormatDate = (date, separator = '.') =>
  moment(date).isValid() ? moment(date).format(['DD', 'MM', 'YYYY'].join(separator)) : '';

// export const formatMillisecondsToHHmmss = (ms: number): string => moment.utc(ms).format('HH:mm:ss');

type TFormat = 'HH:mm:ss';
export const formatMsTo = (ms: number, format: TFormat): string => moment.utc(ms).format(format);

export const dateNow = () => moment().format();

export const formatDateBeforeEightDays: TFormatDate = (date) => {
  const now = moment();
  const sometime = moment(date);
  const daysDiff = now.diff(sometime, 'days');
  if (daysDiff < 8) {
    return moment(date).fromNow();
  }
  return formatDateDDMMYYYY(date);
};
