import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { InterviewFormEdit } from 'components/InterviewElements/InterviewForm/InterviewFormEdit';
import { ModalWarning } from 'components/ui/Modal';

import { hint } from 'components/ui/Hint';
import { routes } from 'routing/routes';
import { TRouterParams } from 'routing/types';
import { useEditInterviewResultMutation } from 'store/interview';
import { TEditInterviewResultRequest, TInterviewEditQuestion } from 'store/interview/types';

const MODAL_MESSAGE = 'Вы уверены, что не хотите сохранить изменения?';

export const InterviewEdit = () => {
  const { candidateId: uid } = useParams<keyof TRouterParams>() as TRouterParams;
  const [modalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState<TInterviewEditQuestion[]>([]);
  const [editInterviewResults, { isLoading, isSuccess, isError }] =
    useEditInterviewResultMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      hint.error('Ошибка во время отправки данных. Попробуйте ещё раз.');
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      hint.success('Изменения успешно сохранены');
    }
  }, [isSuccess]);

  const sendEditedData = (values: TInterviewEditQuestion[]) => {
    setModalOpen(false);
    const editData = values.map((q) => {
      const changedQuestion: TEditInterviewResultRequest[number] = {
        comment: q.comment,
        question: Number(q.id),
      };
      if (q.score) {
        changedQuestion.score = q.score;
      }
      return changedQuestion;
    });
    editInterviewResults({ body: editData, uid });
  };

  const handleOk = () => {
    sendEditedData(values);
  };

  const handleCancel = () => {
    navigate(routes.candidateProfile(uid));
  };

  const onSave = (values: TInterviewEditQuestion[]) => {
    sendEditedData(values);
  };

  const onCancelSave = (values: TInterviewEditQuestion[]) => {
    setValues(values);
    setModalOpen(true);
  };

  if (isSuccess) {
    return <Navigate to={routes.candidateProfile(uid)} />;
  }
  return (
    <>
      <InterviewFormEdit onSave={onSave} isLoading={isLoading} onCancelSave={onCancelSave} />
      <ModalWarning
        handleOk={handleOk}
        handleCancel={handleCancel}
        visible={modalOpen}
        closable={false}
        width={627}
        footer={false}
        okText="Сохранить"
        cancelText="Не сохранять"
        type="attention"
        contentText={MODAL_MESSAGE}
      />
    </>
  );
};
