import { api } from 'network/api/api';
import { EApiTags } from 'network/api/types';
import { apiRoutes } from 'network/apiRoutes';
import { TCommentAboutCandidate, TCommentPostBody } from './types';

export const commentsAboutCandidateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommentsAboutCandidate: builder.query<TCommentAboutCandidate[], string>({
      query: (id) => ({ url: `${apiRoutes.COMMENTS_ABOUT_CANDIDATE}/${id}` }),
      transformResponse: (comments: TCommentAboutCandidate[]) =>
        comments.sort((a, b) => b.createdDate.localeCompare(a.createdDate)),
      providesTags: (_res, _err, id) => [{ id, type: EApiTags.COMMENT_ABOUT_CANDIDATE }],
    }),
    addCommentAboutCandidate: builder.mutation<TCommentAboutCandidate, TCommentPostBody>({
      query: (body) => ({
        url: `${apiRoutes.COMMENTS_ABOUT_CANDIDATE}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err, {candidateId}) => [{id: candidateId, type: EApiTags.COMMENT_ABOUT_CANDIDATE}],
    }),
  }),
});

export const { useAddCommentAboutCandidateMutation, useGetCommentsAboutCandidateQuery } =
  commentsAboutCandidateApi;
