import { CheckboxOptionType } from 'antd';

import { EStage, EStatus } from 'constants/stageProgress';
import { ELevel } from 'constants/level';

const mapValuesToOptions = (source: typeof ELevel | typeof EStage | typeof EStatus) =>
  Object.values(source)
    .map((value): CheckboxOptionType => ({
      label: value,
      value,
    }));

export const levelOptions = mapValuesToOptions(ELevel);
export const stageOptions = mapValuesToOptions(EStage);
export const statusOptions = mapValuesToOptions(EStatus);
