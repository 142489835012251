import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Input, InputProps } from 'antd';

import { ReactComponent as CloseSvg } from 'assets/images/svg/Close.svg';
import { ReactComponent as SearchSvg } from 'assets/images/svg/Search.svg';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import { getCandidatesListFilterByName } from 'store/candidatesList/selectors';
import { CandidatesListActions } from 'store/candidatesList/slice';


const allowClear = { clearIcon: <CloseSvg /> };

export const SearchBarFilter = ({ className }: InputProps) => {
  const dispatch = useAppDispatch();
  const storeSearchValue = useAppSelector(getCandidatesListFilterByName);
  const [searchValue, setSearchValue] = useState(storeSearchValue);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  // Sync with changes from store
  useEffect(() => {
    if (storeSearchValue !== searchValue) {
      setSearchValue(storeSearchValue);
    }
  }, [storeSearchValue]);

  // Debouncing search value
  useEffect(() => {
    if (debouncedSearchValue !== storeSearchValue) {
      dispatch(CandidatesListActions.setFilterByName(debouncedSearchValue));
    }
  }, [debouncedSearchValue]);

  return <Input className={className}
                allowClear={allowClear}
                value={searchValue}
                onChange={onSearchChange}
                prefix={<SearchSvg />}
                placeholder='Поиск кандидата'
  />;
};