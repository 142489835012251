import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Link } from 'react-router-dom';

import { ReactComponent as HomeSvg } from 'assets/images/svg/home.svg';
import { ReactComponent as AddUserSvg } from 'assets/images/svg/add_user.svg';
import { ReactComponent as DistanceSvg } from 'assets/images/svg/distance.svg';

import { routes } from 'routing/routes';

import cx from './index.module.scss';

export const menuItems: ItemType[] = [
  {
    label: <Link to={routes.root}>Главная страница</Link>,
    key: routes.root,
    icon: <HomeSvg className={cx.menu_icon} />,
  },
  {
    label: <Link to={routes.newCandidate}>Регистрация кандидата</Link>,
    key: routes.newCandidate,
    icon: <AddUserSvg className={cx.menu_icon} />,
  },
  {
    label: <Link to={routes.candidatesList}>Список кандидатов</Link>,
    key: routes.candidatesList,
    icon: <DistanceSvg className={cx.menu_icon} />,
  },
];
