import React, { FC } from 'react';

import { SearchBarFilter } from './SearchBarFilter';
import { DateFilter } from './DateFilter';
import { OtherFilters } from './OtherFilters';
import { OnlyChosenFilter } from './OnlyChosenFilter';
import { ResetButton } from './ResetButton';

import cx from './index.module.scss';

export const CandidateListFilters: FC = () => (
  <div className={cx.candidate_list_filters}>
    <SearchBarFilter className={cx.search} />
    <DateFilter className={cx.date_filter}
                dropdownClassName={cx.date_filter_dropdown} />
    <OtherFilters labelClassName={cx.other_filters_label}
                  overlayClassName={cx.other_filters_overlay} />
    <OnlyChosenFilter wrapClassName={cx.only_chosen_filter}
                      labelClassName={cx.only_chosen_filter_label} />
    <ResetButton className={cx.reset_btn}
                 activeClassName={cx.reset_btn_active} />
  </div>
);